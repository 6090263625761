.salmon{color: $salmon;}
.yellow{color: $yellow;}
.blue{color: $blue;}
.green{color: $green;}
.dark{color: $dark;}
.light{color: $light;}
.lighter{color: $lighter;}
.kaki{color: $kaki;}
.red{color: $red;}
.cyan{color: #78acff;}
.primary{color: $primary;}
.secondary{color: $secondary;}
.faded{color: $light;}
