.has-delete{
	position: relative;
	@include transition;

	@include hover-focus-active {
		background-color: lighten($lighter, 2%);
		&::after{opacity: 1;}
	}

	&::after{
		content: "\f00d";
		font-family: 'jobaround';
		position: absolute;
		top: 0;
		right: 0;
		padding: 4px 6px;
		line-height: 1;
		color: $secondary;
		font-size: rem(12);
		@include transition;
		opacity: 0;
		cursor: pointer;
	}
}