// user group

ul.user-list{
	font-size: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-left: 10px;

	li.user-list-item{
		display: inline-block;
		margin-left: -10px;
		width: 35px;
		height: 35px;
		overflow: hidden;
		border-radius: 35px;
		border: 2px solid $white;

		img{
			width: 31px;
			height: 31px;
		}
	}
}