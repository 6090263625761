.card.mission{
	margin-bottom: $spacer * 2;

	.card-header{
		color: $light;
		font-weight: normal;
		font-family: $font-family-base;
	}

	.card-body{
		.mission-details{
			display: flex;
			height: 160px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			.mission-details-status{
				
			}

			.mission-details-role{
				.title{
					text-transform: uppercase;
					color: $secondary;
					font-size: rem(13);
				}

				.role{
					font-weight: 700;
				}
			}
		}

		.mission-actions{
			display: flex;
			flex-wrap: wrap;
			margin-left: -$spacer / 2;
			margin-right: -$spacer / 2;

			.mission-action{
				flex: 1 0 auto;
				margin: $spacer / 2;
			}
		}
	}

	.card-footer{
		padding: 0;

		a{
			color: $dark;
			padding: $spacer;
			display: block;
			background-color: lighten($lighter, 2%);

			&:hover{
				background-color: $lighter;
				color: $primary;
				text-decoration: none;
			}
		}
	}
}