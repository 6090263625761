@font-face {
  font-family: 'jobaround';
  src:  url('../fonts/jobaround.eot?sbu2rj');
  src:  url('../fonts/jobaround.eot?sbu2rj#iefix') format('embedded-opentype'),
    url('../fonts/jobaround.ttf?sbu2rj') format('truetype'),
    url('../fonts/jobaround.woff?sbu2rj') format('woff'),
    url('../fonts/jobaround.svg?sbu2rj#jobaround') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after{
    font-family: 'jobaround' !important;
  }
}

// loader

.icon-loader{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 18px;
  height: 18px;
  line-height: .8em;

  @keyframes animRotate{
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }

  &::before,
  &::after{
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 3px solid $lighter;
    border-radius: 18px;
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before{
  }

  &::after{
    border-color: transparent;
    border-bottom-color: $primary;
    animation: animRotate .5s linear infinite;
  }
}

// fonticon

.icon-copy:before {
  content: "\e945";
}
.icon-download:before {
  content: "\e944";
}
.icon-info:before {
  content: "\e943";
}
.icon-b-businessetstrategie:before {
  content: "\e935";
  color: #848fa6;
}
.icon-b-communication:before {
  content: "\e936";
  color: #848fa6;
}
.icon-b-comptabiliteetfinance:before {
  content: "\e937";
  color: #848fa6;
}
.icon-b-design:before {
  content: "\e938";
  color: #848fa6;
}
.icon-b-juridique:before {
  content: "\e939";
  color: #848fa6;
}
.icon-b-marketing:before {
  content: "\e93a";
  color: #848fa6;
}
.icon-b-ressourceshumaines:before {
  content: "\e93b";
  color: #848fa6;
}
.icon-b-techetdata:before {
  content: "\e93c";
  color: #848fa6;
}
.icon-s-beaute:before {
  content: "\e93d";
  color: #848fa6;
}
.icon-s-coursparticuliers:before {
  content: "\e93e";
  color: #848fa6;
}
.icon-s-loisirsetevenements:before {
  content: "\e93f";
  color: #848fa6;
}
.icon-s-recherche:before {
  content: "\e940";
  color: #848fa6;
}
.icon-b-recherche:before {
  content: "\e940";
  color: #848fa6;
}
.icon-s-sport:before {
  content: "\e941";
  color: #848fa6;
}
.icon-s-transportetmanutention:before {
  content: "\e942";
  color: #848fa6;
}
.icon-s-aideadomicile:before {
  content: "\e90f";
  color: #848fa6;
}
.icon-s-bienetre:before {
  content: "\e910";
  color: #848fa6;
}
.icon-s-coaching:before {
  content: "\e911";
}
.icon-s-depannageettravaux:before {
  content: "\e912";
  color: #848fa6;
}
.icon-s-loisirsetevenement:before {
  content: "\e913";
}
.icon-s-sportetfitness:before {
  content: "\e914";
}
.icon-check-alt:before {
  content: "\e932";
}
.icon-main-accounting:before {
  content: "\e931";
}
.icon-euro:before {
  content: "\e92d";
}
.icon-bus:before {
  content: "\e92c";
}
.icon-main-plane:before {
  content: "\e930";
}
.icon-main-lightbulb:before {
  content: "\e92e";
}
.icon-main-molecules:before {
  content: "\e92f";
}
.icon-main-folder:before {
  content: "\e928";
}
.icon-main-pin:before {
  content: "\e929";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-share-alt:before {
  content: "\f064";
}
.icon-phone:before {
  content: "\f095";
}
.icon-share:before {
  content: "\f1e0";
}
.icon-signs:before {
  content: "\f277";
}
.icon-main-logout:before {
  content: "\e926";
}
.icon-main-switch-account:before {
  content: "\e927";
}
.icon-main-arrow-left:before {
  content: "\e924";
}
.icon-main-chat:before {
  content: "\e920";
}
.icon-main-quotation:before {
  content: "\e923";
}
.icon-main-check:before {
  content: "\e921";
}
.icon-main-close:before {
  content: "\e922";
}
.icon-star-100:before {
  content: "\f005";
}
.icon-star-0:before {
  content: "\f006";
}
.icon-check:before {
  content: "\f00c";
}
.icon-trash:before {
  content: "\f014";
}
.icon-clock:before {
  content: "\f017";
}
.icon-edit:before {
  content: "\f044";
}
.icon-note:before {
  content: "\f044";
}
.icon-crosshairs:before {
  content: "\f05b";
}
.icon-thumbs-up:before {
  content: "\f087";
}
.icon-upload:before {
  content: "\f093";
}
.icon-credit-card-alt:before {
  content: "\f09d";
}
.icon-paperclip:before {
  content: "\f0c6";
}
.icon-star-50:before {
  content: "\f123";
}
.icon-map:before {
  content: "\f278";
}
.icon-arrow-up-alt:before {
  content: "\e915";
}
.icon-add-alt:before {
  content: "\e916";
}
.icon-add:before {
  content: "\e90c";
}
.icon-arrow-down-alt:before {
  content: "\e908";
}
.icon-arrow-left-alt:before {
  content: "\e909";
}
.icon-main-home:before {
  content: "\e90d";
}
.icon-main-settings:before {
  content: "\e90e";
}
.icon-main-menu:before {
  content: "\e90b";
}
.icon-main-refresh:before {
  content: "\e918";
}
.icon-s-1:before {
  content: "\e91a";
}
.icon-s-3-6:before {
  content: "\e91b";
}
.icon-s-1-3:before {
  content: "\e91c";
}
.icon-s-6:before {
  content: "\e91d";
}
.icon-main-search:before {
  content: "\e905";
}
.icon-main-location:before {
  content: "\e90a";
}
.icon-main-timer:before {
  content: "\e919";
}
.icon-main-tag:before {
  content: "\e917";
}
.icon-main-bell:before {
  content: "\e907";
}
.icon-main-no-idea:before {
  content: "\e91e";
}
.icon-main-document:before {
  content: "\e91f";
}
.icon-main-calendar:before {
  content: "\e900";
}
.icon-main-envelope:before {
  content: "\e901";
}
.icon-main-network:before {
  content: "\e902";
}
.icon-main-user:before {
  content: "\e903";
}
.icon-main-users:before {
  content: "\e904";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope:before {
  content: "\f003";
}
.icon-close:before {
  content: "\f00d";
}
.icon-camera:before {
  content: "\f030";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-pen:before {
  content: "\f040";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-arrows-v:before {
  content: "\f07d";
}
.icon-arrows-h:before {
  content: "\f07e";
}
.icon-heart:before {
  content: "\f08a";
}
.icon-linkedin:before {
  content: "\f08c";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-credit-card:before {
  content: "\f283";
}
.icon-logo:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e934";
}
.icon-link:before {
  content: "\e933";
}
.icon-chain:before {
  content: "\e933";
}
.icon-url:before {
  content: "\e933";
}
.icon-uri:before {
  content: "\e933";
}
.icon-anchor:before {
  content: "\e933";
}
.icon-minus:before {
  content: "\e92a";
}
.icon-plus:before {
  content: "\e92b";
}
.icon-more:before {
  content: "\e925";
}