// PHOTOS

.photo.card{
	margin-bottom: $spacer * 2;
	transition: box-shadow .3s ease-out;

	&:hover{
		box-shadow: 0 5px 20px rgba(0,0,0,.2);

		.photo-thumbnail-upload{
			opacity: 1;
			visibility: visible;
		}
	}

	.card-img-top{
		height: 180px;

		.img-top-container{
			width: 100%;
			height: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $gray-200;

			img{
				width: 100%;
			}

		}
	}

	.photo-thumbnail-upload{
		opacity: 0;
		visibility: hidden;
		@include transition;
	}

	.photo-description{
		font-weight: 700;
		min-height: 21px;
	}

	.photo-date{
		font-size: rem(12);
		color: $secondary;
		min-height: 18px;
	}
}