nav{
	&.dashboard-main_nav{
		z-index: $zindex-dashboard-main_nav;
		
		@include media-breakpoint-down(md) {
			width: $header-height-mobile;
		}

		@include media-breakpoint-up(lg) {
			width: $header-height-desktop;
			min-height: calc(100vh - #{$header-height-desktop});
		}

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			background-color: $white;
			border-right: 1px solid $border-color;
			position: relative;
			@include transition;

			@include media-breakpoint-down(md) {
				left: -$header-height-mobile;
			}

			@include media-breakpoint-up(lg) {
				height: calc(100vh - #{$header-height-desktop});
				overflow: hidden;
			}

		}

		&.active{
			@include media-breakpoint-down(md) {

				> ul {
					height: calc(100% - #{$header-height-mobile});
					left: 0;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			&:hover{
				width: auto;

				.menu_name{
					opacity: 1;
				}
			}
		}

		.nav-dashboard-main_nav-mobile-trigger{
			background-color: $yellow;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		// logo

		i.icon-logo{
			background-color: $primary;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			font-size: rem(26);

			@include media-breakpoint-down(md) {
				height: $header-height-mobile;
				width: $header-height-mobile;
			}

			@include media-breakpoint-up(lg) {
				height: $header-height-desktop;
				width: $header-height-desktop;
			}
		}

		// nav

		ul{
			li{
				width: 100%;
				display: flex;
				align-items: center;
				position: relative;

				i{
					font-size: rem(22);
					position: relative;
					width: 22px;
					height: 24px;
					display: inline-block;
					@include transition;

					@include media-breakpoint-up(lg) {
						margin-right: $spacer * 2;	
					}
					
				}

				.menu_name{
					opacity: 0;
					@include transition;
				}

				&.active{
					a{
						color: $primary;
					}
				}

				&.required{
					i::after{
						content:"";
						width: 8px;
						height: 8px;
						background-color: $red;
						border-radius: 8px;
						position: absolute;

						@include media-breakpoint-down(md) {
							top: .8rem;
							right: .6rem
						}

						@include media-breakpoint-up(lg) {
							top: .2rem;
							right: -.2rem;
						}
					}
				}

				a{
					color: $light;
					width: 100%;
					display: flex;
					align-items: center;
					white-space: nowrap;

					@include media-breakpoint-down(md) {
						padding: rem(6) rem(13);
					}

					@include media-breakpoint-up(lg) {
						padding: rem(10) rem(23);
					}

					&:hover{
						text-decoration: none;
					}
				}

				@include media-breakpoint-up(lg) {
					&:hover{
						a{color: $dark;}
					}
				}
			}
		}

	}

	&.dashboard-sub_nav,
	&.dashboard-profile-header_nav,
	&.profile-header_nav{
		background-color: $white;
		font-weight: 500;
		border-bottom: 1px solid $border-color;
		display: flex;
		align-items: center;
		letter-spacing: 0;

		@include media-breakpoint-down(sm) {
			min-height: $header-height-mobile;
			padding: $spacer / 2 $spacer;
		}

		@include media-breakpoint-up(md) {
			padding: 1.4rem 2.1rem;
		}

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
			position: relative;

			li{
				display: inline-block;
				margin-right: $spacer;
			}
		}
	}

	&.dashboard-sub_nav,
	&.dashboard-profile-header_nav{
		@include media-breakpoint-up(md) {
			// triangle
			ul::after{
				content:'';
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				transform: rotate(-45deg);
				background-color: $white;
				bottom: -33px;
				left: $spacer * 3;
				border-left: 1px solid $border-color;
				border-bottom: 1px solid $border-color;
			}
		}
	}

	&.dashboard-sub_nav{

		@include media-breakpoint-down(sm) {
			min-height: $header-height-mobile;
			padding: $spacer / 2 $spacer;
		}

		ul{
			li{
				position: relative;

				&.active{
					a{color: $primary;}
				}

				&.required{
					&::after{
						content: '';
						width: 8px;
						height: 8px;
						background-color: $red;
						border-radius: 8px;
						position: absolute;
						top: 0;
						right: -10px;
					}
				}

				a{
					color: $light;
				}
			}
		}
	}

	&.dashboard-profile-header_nav,
	&.profile-header_nav{
		margin-bottom: $spacer * 2;
		position: relative;

		ul{
			li{

				&.active{
					a{color: $dark;}
				}

				&.required{
					position: relative;

					&::after{
						content:"";
						width: 8px;
						height: 8px;
						background-color: $red;
						border-radius: 8px;
						position: absolute;
						top: 0px;
						right: -.7rem;
					}
				}

				a{color: $light;}
			}
		}

		@include media-breakpoint-only(md) {
			padding-left: 10rem;
			&::after{left: 13rem;}
		}

		@include media-breakpoint-up(lg) {
			padding-left: 14rem;
			&::after{left: 17rem;}
		}
	}

	&.profile-header_nav{
		@include media-breakpoint-down(sm) {
			padding: $spacer;
		}

		@include media-breakpoint-down(md) {
			overflow-x: auto; 

			ul{
				width: inherit;
				white-space: nowrap;

				li{
					a{}
				}
			}
		}

		@include media-breakpoint-only(md) {
			padding: $spacer * 2 $spacer $spacer $spacer;
		}

		@include media-breakpoint-only(lg) {
			padding: 1.4rem 2.1rem;

			ul{
				max-width: 60%;
				white-space: nowrap;
				overflow-x: auto;
			}
		}
	}

	&.profile-header-more-nav{
		position: absolute;
		top: 1.2rem;
		right: $spacer;
		z-index: 3;
		color: $white;

		.nav-profile-header-more-trigger{
			cursor: pointer;
			
			i{
				font-size: rem(22);

				&::before{
					transform: rotate(90deg);
					display: inline-block;
				}
			}
		}

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 1rem;
			right: 0;
			background-color: $white;
			padding: $spacer;
			border-radius: 3px;

			opacity: 0;
			visibility: hidden;
			@include transition;

			li{
				a{}
			}
		}

		&.active{
			ul{
				visibility: visible;
				opacity: 1;
				top: 1.7rem;
			}
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&.profile-actions-share-nav{
		position: relative;
		z-index: 3;

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 2rem;
			right: 0;
			background-color: $white;
			padding: $spacer;
			border-radius: 3px;
			box-shadow: 0 2px 10px rgba(0,0,0,.4), 0 10px 40px rgba(0,0,0,.1);

			opacity: 0;
			visibility: hidden;
			@include transition;

			&::after{
				content:'';
				border: 6px solid transparent;
				border-bottom-color: $white;
				position: absolute;
				top: -12px;
				right: .8rem;
			}

			li{
				text-align: left;
				line-height: 1em;

				a{
					font-size: rem(12);
					color: $dark;
					padding: 4px 0;
					display: flex;
					align-items: center;

					i{
						color: $light;
						width: 16px;
						font-size: 16px;
						display: inline-block;
						text-align: center;
						margin-right: $spacer / 2;
					}

					@include hover-focus-active {
						color: $darker;
						text-decoration: none;

						i{
							color: $secondary;
						}
					}
				}
			}
		}

		&.active{
			ul{
				visibility: visible;
				opacity: 1;
				top: 3rem;
			}
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&.default-main_nav{
		display: flex;
		justify-content: flex-end;
		color: $white;

		.notification.has-new i::after{border-color: $white;}

		> ul{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;

			> li{
				display: inline-block;

				> a{
					display: inline-block;
					color: $white;
					font-size: rem(13);
					margin: 0;
					padding: $spacer / 2;
					@include hover-focus-active {
					}
				}
			}
		}
	}

	&.dashboard-agenda_nav,
	&.dashboard-messagerie_nav{
		@include media-breakpoint-down(md) {
			min-height: $header-height-mobile;
			padding: $spacer / 2 $spacer;
		}

		@include media-breakpoint-up(lg) {
			padding: 1rem;
			height: $subnav-height-desktop;
		}
	}

	&.dashboard-agenda_nav{
		ul{
			list-style: none;
			margin: 0;
			padding: 0;

			li{
				display: inline-block;
			}
		}

		ul.nav{
			display: flex;
			align-items: center;
			height: 100%;
			position: relative;
			z-index: 5;

			>li{
				position: relative;
				margin-right: .5rem;

				a{
					display: inline-block;
					padding: .5rem 1rem;
					text-decoration: none;
					color: $secondary;
					@include transition;

					i{
						color: $primary;

						&.icon-angle-down{
							color: $dark;
						}
					}

					@include hover-focus-active {
						color: $dark;
					}
				}

				&:hover{
					>ul{
						opacity: 1;
						visibility: visible;
					}
				}

				>ul{
					$arrow-width: 10px;

					@include media-breakpoint-down(sm) {
						$arrow-width: 4px;
					}

					position: absolute;
					left: 0;
					width: 100%;
					opacity: 0;
					visibility: hidden;
					z-index: 3;
					top: 100%;
					@include transition;

					

					&::before{
						content:'';
						display: block;
						width: $arrow-width;
						height: $arrow-width / 2;
						border: $arrow-width solid $white;
						border-top-color: transparent;
						border-right-color: transparent;
						border-left-color: transparent;
						margin-left: -$arrow-width;
						left: 50%;
						z-index: 2;
						position: relative;

						@include media-breakpoint-down(sm) {
							left: 10px;
						}
					}

					&::after{
						content: '';
						display: block;
						box-shadow: 0 2px 10px rgba(0,0,0,.2), 0 10px 40px rgba(0,0,0,.1);
						position: absolute;
						top: $arrow-width * 2;
						width: 100%;
						height: calc(100% - #{$arrow-width * 2});
						border-radius: 4px;
						z-index: 1;
					}

					>li{
						display: block;
						position: relative;
						z-index: 3;

						&.active{
							a{
								background-color: $primary;
								color: $white;
							}
						}

						&:first-child a{border-radius: 4px 4px 0 0;}
						&:last-child a{border-radius: 0 0 4px 4px; border-bottom: 0;}

						a{
							background-color: $white;
							display: inline-block;
							width: 100%;
							padding: .5rem 1rem;
							border-bottom: 1px solid $lighter;
							color: $secondary;
							text-decoration: none;
							@include transition;

							@include hover-focus-active {
								background-color: $lighter;
								color: $dark;
							}
						}
					}
				}
			}

			@include media-breakpoint-only(xs) {
				flex-wrap: wrap;

				> li{
					flex: 1 0 100%;
					font-size: rem(13);

					a{
						padding: 4px;
					}
				}
			}

			@include media-breakpoint-only(sm) {
				flex-wrap: wrap;

				>li{
					flex: 1 0 calc(50% - 1rem);
					font-size: rem(12);
					width: 50%;
					letter-spacing: 0;

					a{
						padding: 4px;
					}
				}
			}
		}
	}

	&.dashboard-messagerie_nav{

	}

	&.dashboard-project-header_nav{
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			display: block;
			font-size: 0;

			@include media-breakpoint-down(sm) {
				white-space: nowrap;
				overflow-x: auto;
				margin-left: -$spacer;
			}

			li{
				display: inline-block;

				a{
					color: $secondary;
					text-transform: uppercase;
					padding: $spacer / 2 $spacer;
					display: inline-block;
					position: relative;
					font-size: rem(14);

					&::after{
						content:'';
						display: block;
						position: absolute;
						bottom: 0;
						width: calc(100% - #{$spacer} * 2);
						height: 3px;
					}
				}

				&.active{
					a{
						color: $white;

						&::after{
							background-color: $primary;
						}
					}

				}

				&:hover {
					a{
						text-decoration: none;
						color: $white;

						&::after{
							background-color: $white;
						}
					}
				}

				@include media-breakpoint-up(md) {
					&:first-child a{
						padding-left: 0;

						&::after{
							width: calc(100% - #{$spacer});
						}
					}
				}
				
			}
		}
	}

	&.footer_nav{
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
		}

		a{
			color: $secondary;
			@include hover-focus-active {
				text-decoration: none;
				color: $dark;
			}
		}

		> ul{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			>li{
				margin-right: $spacer;
				&:last-child{
					margin-right: 0;
				}

				>ul{

					>li{
						font-size: rem(12);
						line-height: 2em;
					}
				}
			}
		}
	}

	&.user-main_nav,
	&.user-messages_nav,
	&.user-notifications_nav{
		position: absolute;
		background-color: $white;
		border-radius: 3px;
		color: $dark;
		font-size: rem(13);
		box-shadow: 0 1px 4px rgba(0,0,0,.2), 0 10px 40px rgba(0,0,0,.2);
		@include transition;
		z-index: $zindex-user-main_nav;

		opacity: 0;
		visibility: hidden;

	}

	&.user-main_nav{
		
		@include media-breakpoint-down(md) {
			top: 50px;
			border-radius: 0;
			width: 300px;

			.user-main_nav-wrapper{
				&::before,
				&::after{
					display: none;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			top: 48px;
			right: -20px;
			min-width: 220px;
		}
	}

	&.user-messages_nav{
		top: 48px;
		right: -4px;
	}

	&.user-notifications_nav{
		top: 48px;
		right: 11px;
	}

	.user-main_nav-wrapper,
	.user-messages-wrapper,
	.user-notifications-wrapper{

		&::before{
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			border: 8px solid transparent;
			position: absolute;
			top: -16px;
			right: 16px;
			border-bottom-color: $white;

		}

		.nav-section-title{
			text-transform: uppercase;
			color: $light;
			padding: $spacer / 2 $spacer;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			font-size: rem(10);
			user-select: none;

			&:first-child{
				border-top-color: transparent;
			}
		}

		ul{
			margin: $spacer 0;
			padding: 0;

			li{
				display: block;

				a{
					display: inline-flex;
					width: 100%;
					color: $dark;
					font-weight: 700;
					align-items: center;
					padding: 6px $spacer;

					i{
						color :$light;
						font-size: rem(22);
						margin-right: $spacer / 2;
					}

					&:hover{
						background-color: $lighter;
						text-decoration: none;
					}
				}
			}
		}
	}

	.user-main_nav-wrapper{
		.switch-mode{
			.btn:not(.active){
				padding: 8px 12px;
			}

			i{
				font-size: rem(24);
			}
		}
	}

	.user-notifications-wrapper,
	.user-messages-wrapper{
		.nav-section-title{
			display: flex;
			justify-content: space-between;

			.title{
				color: $light;
			}

			a{
				color: $dark;
			}
		}

		.nav-section-footer{
			.btn{
				border-radius: 0 0 3px 3px;
			}
		}

		.jobaround-notification{
			padding: $spacer;
			width: 400px;

			& + .jobaround-notification{
				border-top: 1px solid $border-color;
			}
		}
	}

	&.tabnav{
		ul.tabnav-list{
			list-style: none;
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $border-color;
			border-top: 1px solid $border-color;

			li.tabnav-list-item{
				display: inline-block;
				margin-right: $spacer * 2;
				position: relative;
				bottom: -1px;

				&.active a{
					color: $dark;
					border-color: $primary;
				}

				a{
					color: $light;
					text-transform: uppercase;
					font-weight: 700;
					padding: $spacer * 3 / 2 0;
					border-bottom: 2px solid transparent;
					text-decoration: none;
				}
			}
		}
	}

	&.links{

		ul{
			list-style: none;
			margin: 0;
			padding: 0;
		}

		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
			left: 0;

			$menu-padding: $spacer;
			$menu-margin-top: 20px;
			$arrow-size: 10px;
			$arrow-position-right: 6px;
			$menu-background-color: $white;

			.nav-links-mobile-trigger{
				font-size: rem(22);
				cursor: pointer;
				height: $header-height-mobile;
				width: $header-height-mobile;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			ul{
				margin-top: $menu-margin-top;
				position: absolute;
				left: $spacer / 2;
				background-color: $menu-background-color;
				padding: $menu-padding;
				border-radius: 3px;
				transition: all 0.2s ease-in-out;
				z-index: $zindex-user-main_nav;
				box-shadow: 0 1px 4px rgba(0,0,0,.2), 0 10px 40px rgba(0,0,0,.2);
				opacity: 0;
				visibility: hidden;

				&::before{
					content: "";
					display: block;
					width: 100%;
					height: $menu-margin-top;
					position: absolute;
					top: -$menu-margin-top;
					right: 0;
				}

				&::after{
					content: "";
					border: $arrow-size solid transparent;
					position: absolute;
					top: -$arrow-size * 2;
					left: $arrow-position-right;
					border-bottom-color: $menu-background-color;
				}

				li{
					display: block;
					margin-bottom: $spacer / 2;

					&:last-child{
						margin-bottom: 0;
					}

					.btn-outline-white{
						border-color: $dark;
						color: $dark;
						width: 100%;
						text-align: center;
					}

					a{
						color: $dark;
						display: inline-block;
						width: 100%;
					}
				}
			}

			&:hover{
				ul,
				&::before{
					opacity: 1;
					visibility: visible;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.nav-links-mobile-trigger{
				display: none;
			}

			ul{
				li{
					display: inline-block;
					margin: 0 $spacer / 3;

					a{
						color: $white;
					}

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}

	&.search-mobile{
		font-size: rem(22);
	}
}