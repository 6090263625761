// PAYMENT METHOD

	.payment-method{
		position: relative;
		margin-bottom: $spacer * 2;

		@include media-breakpoint-only(xs) {padding-bottom: 65%;}
		@include media-breakpoint-only(sm) {padding-bottom: 32%;}
		@include media-breakpoint-only(md) {padding-bottom: 32%;}
		@include media-breakpoint-only(lg) {padding-bottom: 22%;}
		@include media-breakpoint-only(xl) {padding-bottom: 18%;}

		.pm-wrapper{
			position: absolute;
			top: 0;
			right: $spacer;
			bottom: 0;
			left: $spacer;
			display: flex;
			flex-direction: column;

			.pm-content{
				background-color: $primary;
				padding: $spacer;
				flex: 1 0 auto;
				color: $white;
				display: flex;
				flex-direction: column;
				@include border-radius;

				.pm-logo{
					flex: 1 0 auto;

					img{
						max-height: 30px;
						width: auto;
					}
				}

				.pm-numbers,
				.pm-expires{
					text-align: right;
					right: $spacer;
				}

				.pm-numbers{
					font-size: rem(18);
				}
			}

			.pm-footer{
				margin-top: $spacer / 2;
				flex: 0 0 28px;
			}
		}

		&.add-payment-method{

			.pm-content{
				border: 1px dashed $light;
				background-color: transparent;
				color: $light;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.add{
				display: inline-block;
				background-color: $light;
				color: $white;
				width: 34px;
				height: 34px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 34px;
			}

			p{
				margin-top: $spacer;
				margin-bottom: 0;
				text-align: center;
			}
		}
	}