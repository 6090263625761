form.stripe{
	.form-control{
		&.StripeElement{
			padding: 1.307rem 0.907rem 0.507rem 1.2rem !important;
		}
	}
}

//.example.example2 .baseline {
//	position: absolute;
//	width: 100%;
//	height: 1px;
//	left: 0;
//	bottom: 0;
//	background-color: #cfd7df;
//	transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
//}
