.new_message{
	border-radius: 42px;
	background-color: $primary;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 42px;
	font-size: rem(18);
	box-shadow: 0 2px 10px rgba(0,0,0,.1);
	position: fixed;
	bottom: $spacer * 2;
	right: $spacer * 2;
	z-index: 999;
	opacity: 0;
	visibility: hidden;

	&.active{
		opacity: 1;
		visibility: visible;
	}
}