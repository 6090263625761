// TAGS

.tag{
	background-color: $lighter;
	display: inline-block;
	color: $secondary;
	padding: .2rem 1rem;
	font-size: rem(12);
	font-weight: normal;
	border-radius: 1rem;
	margin-bottom: $spacer / 2;

	&.tag-green,
	&.tag-success{
		background-color: $green;
		color: $white;
		@include hover-focus-active {background-color: darken($green,10%);}
	}

	&.tag-primary{
		background-color: $primary;
		color: $white;
		@include hover-focus-active {background-color: darken($primary,10%);}
	}

	&.tag-secondary{
		background-color: $secondary;
		color: $white;
		@include hover-focus-active {background-color: darken($secondary,10%);}
	}

	&.tag-light{
		background-color: $light;
		color: $white;
		@include hover-focus-active {background-color: darken($light,10%);}
	}

	&.tag-lighter{
		background-color: $lighter;
		color: $secondary;
		@include hover-focus-active {background-color: darken($lighter,10%);}
	}

	&.tag-blue{
		background-color: $blue;
		color: $white;
		@include hover-focus-active {background-color: darken($blue,10%);}
	}

	&.tag-salmon{
		background-color: $salmon;
		color: $white;
		@include hover-focus-active {background-color: darken($salmon,10%);}
	}

	&.tag-warning{
		background-color: $warning;
		color: $white;
		@include hover-focus-active {background-color: darken($warning,10%);}
	}

	&.tag-danger{
		background-color: $salmon;
		color: $white;
		@include hover-focus-active {background-color: darken($salmon,10%);}
	}
}
