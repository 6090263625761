// OR

.or{
	display: flex;
	align-items: center;
	font-size: rem(10);
	color: $secondary;
	font-family: $font-family-alt;
	margin-bottom: $spacer;

	span{
		margin: 0 $spacer * 2;
	}

	&::before,
	&::after{
		content: '';
		display: inline-block;
		border-bottom: 1px solid $light;
		width: 100%;
	}
}
