aside.filters{
	font-size: rem(12);
	letter-spacing: 0;

	@include media-breakpoint-down(md) {
		max-height: 0;
		@include transition;
		overflow: hidden;

		&.display{
			max-height: 1200px;
		}
	}

	.filters-title{
		display: flex;
		justify-content: space-between;

		span{
			font-weight: 700;
		}

		a{
			color: $secondary;
			text-decoration: none;

			@include hover-focus-active {
				color: darken($secondary, 20%);
			}
		}
	}

	.filter{
		margin: $spacer 0;

		.filter-title{
			text-transform: uppercase;
			font-weight: bold;
			border: 1px solid $light;
			border-left: 0;
			border-right: 0;
			color: $secondary;
			font-size: rem(10);
			padding: $spacer / 2 0;
			margin-bottom: $spacer;
		}

		.filter-content{
			margin-left: $spacer;

			&.range{
				margin-left: 0;
				margin-top: $spacer * 3;
			}
		}
	}

	.display-map{
		margin-bottom: $spacer;
	}
}
