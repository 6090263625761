// CARD JOBAROUND

.card{
	position: relative;

	&.card-muted{
		background-color: $lighter;

		.card-header{
			background-color: $lighter;
		}
	}

	.card-title{
		font-family: $font-family-alt;
		font-weight: 700;
	}

	// replacement for the card-body

	.card-element{
		padding: $card-spacer-x;
		border-bottom: 1px solid $card-border-color;
		position: relative;

		&:last-of-type{
			border-bottom: 0;
		}

		.delete{
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			z-index: 2;
		}
	}

	.card-body .card-header{
		border-radius: 0;
		margin-left: -#{$card-spacer-x};
		margin-right: -#{$card-spacer-x};
		margin-top: $spacer;
		margin-bottom: $spacer;
		border-top: 1px solid $card-border-color;
		border-bottom: 1px solid $card-border-color;
	}


	// actions on the top right zone

	.card-actions{
		position: absolute;
		top: $spacer;
		right: -7px;
		@include transition;

		.action{
			margin-bottom: $spacer / 2;

			.badge{
				cursor: pointer;
			}

			.badge-sm{
				margin-right:7px;
			}
		}
	}

	//card button

	.card-btn{
		.btn{
			width: 100%;
			border-radius: 0 0 2px 2px;
			margin-left: -1px;
			margin-top: -1px;
			margin-bottom: -1px;
			margin-right: -1px;
		}
	}

	// cards


	&.infos{
		color: $secondary;
		font-size: rem(13);
	}

	&.recommendations,
	&.acces{
		border: 0;

		.card-header,
		.card-element{
			background-color: $body-bg;
		}
	}

	&.devis{
		background-color: $dark;
		color: $white;
		font-weight: 300;
		line-height: 1;
		cursor: pointer;
		@include transition;

		&:hover{
			background-color: lighten($dark, 10%);
		}

		strong{
			font-weight: 700;
		}

		.script{
			color: $yellow;
			display: inline-block;
			position: relative;
			top: 2px;
		}

		i{
			font-size: rem(22);
			color: $yellow;
		}
	}

	&.interlocutor{
		background-color: #edeff4;
		border-color: #edeff4;
		line-height: 1.2;

		.user-thumbnail{
			width: 36px;
			height: 36px;

			img{
				width: 36px;
				height: 36px;
				border-radius: 36px;
			}
		}

		a{
			color: $salmon;

			@include hover-focus-active {
				color: $salmon;
			}
		}
	}

	&.address{
		.card-body{
			line-height: 1.2;

			i{
				font-size: rem(22);
			}

			.address-element{
				display: flex;

				i{
					flex: 0 0 auto;
				}

				small{
					margin-left: 6px;
				}
			}
		}
	}

	&.message{
		background-color: $dark;
		color: $white;
		text-transform: uppercase;
		cursor: pointer;
		@include transition;

		&:hover{
			background-color: lighten($dark, 10%);
		}
	}

	&.payment-details{
		.card-element{
			padding-left: 0;
			padding-right: 0;

			&:first-child{
				padding-top: 0;
			}

			&:last-child{
				padding-bottom: 0;
			}

			font-size: rem(12);
			font-family: $font-family-alt;

			.col-6:first-child{
				color: $secondary;
			}

			.col-6:last-child{
				text-align: right;
				font-weight: 700;
			}
		}
	}

	&.profile-details{
		box-shadow: 0 4px 40px rgba(0,0,0,.1);

		.card-element{
			line-height: 1.2;

			i{
				font-size: rem(26);
				color: $secondary;
			}

			strong{
				font-size: rem(12);
			}
		}
	}

	&.demande-overview{
		.demande-overview-header{
			display: flex;

			.user-thumbnail{
				flex: 0 0 36px;
				width: 36px;
				height: 36px;
				border-radius: 36px;
				overflow: hidden;

				img{
					width: 100%;
					height: 100%;
				}
			}

			.demande-overview-details{
				margin-left: $spacer / 2;
				line-height: 1.4;

				strong{
					font-family: $font-family-alt;
				}
			}
		}
		.demande-overview-place,
		.demande-overview-date{
			display: flex;
			font-size: rem(12);
			line-height: 1.2;
			padding: 0 $spacer;
			border-left: 1px solid $border-color;

			.icon{}

			.details{
				margin-left: $spacer / 2;
			}

			i{
				font-size: rem(26);
				color: $light;
			}
		}
	}

	&.title{
		font-weight: 700;
		font-family: $font-family-base;
	}

	&.mission,
	&.experts_et_propositions{

		&.deploy.not-deployed{
			.card-header{
				border-bottom: 0;
				@include border-radius;

				.mission-header{
					@include border-radius;
				}
			}

			.card-body{
				display: none;
			}
		}

		&.deploy.not-deployed{
			.card-header .mission-deploy{
				i.icon-plus{
					display: inline-block;
				}
				i.icon-minus{
					display: none;
				}
			}

		}

		.card-body{
			.table thead th{
				border-top: 0;
			}
		}

		.mission-header{
			display: flex;
			padding: $spacer;
			border-radius: $border-radius $border-radius 0 0;
			flex-wrap: wrap;
			align-items: center;
			position: relative;

			.mission-delete{
				display: none;
			}

			.mission-deploy{
				flex: 0 0 40px;
				text-align: right;
				cursor: pointer;

				&:hover{
					color: $secondary;
				}

				i.icon-plus{
					display: none;
				}
				i.icon-minus{
					display: inline-block;
				}
			}

			&.active{
				background-color: lighten($lighter, 2%);
			}

			&.activable{
				cursor: pointer;

				.mission-delete{
					display: block;
					position: absolute;
					top: 5px;
					right: 8px;
					@include transition;
					color: $secondary;
					opacity: 0;
					visibility: hidden;

					&:hover{
						color: $salmon;
					}
				}
			}

			&.activable:hover{
				background-color: $lighter;

				.mission-delete{
					opacity: 1;
					visibility: visible;
				}
			}

			&+.mission-header{
				border-top: 1px solid $border-color;
			}

			.mission-freelance{
				display: flex;
				line-height: 1.2;
				flex: 1 0 auto;

				@include media-breakpoint-down(sm) {
					flex: 0 1 100%
				}

				.mission-freelance-thumbnail{
					width: 36px;
					height: 36px;
					border-radius: 36px;
					overflow: hidden;
					margin-right: $spacer / 2;

					img{
						width: 100%;
						height: 100%;
					}
				}

				.freelance-details-name{
					font-weight: 700;
					font-family: $font-family-alt;
					color: $dark;
				}

				.freelance-details-role{
					color: $secondary;
					font-size: rem(12);
				}

				.freelance-details-link{
					a{
						color: $salmon;
						font-size: rem(13);
					}
				}
			}

			.mission-paid,
			.mission-to_pay,
			.mission-quotation,
			.mission-actions,
			.mission-status,
			.mission-budget{
				flex: 0 0 auto;
				margin-left: $spacer * 2;
				line-height: 1.2;

				@include media-breakpoint-down(sm) {
					flex: 1 0 50%;
					margin: $spacer 0 0;
				}

				.amount{
					font-size: rem(24);
					font-weight: 700;
				}
			}

			.mission-actions{
				@include media-breakpoint-up(md) {
					margin-right: $spacer;
				}
			}
		}

		.table{
			.amount{
				font-size: rem(18);

				.btn{
					margin-left: $spacer / 2;
				}
			}

			.disabled .percentage{
				color: $light;
			}
		}
		
	}

	&.project-payment-overview{
		.amount{
			font-size: rem(20);
			font-weight: 700;
		}
	}

	&.mission-details{

		.resume{
			display: flex;

			@include media-breakpoint-only(xs) {
				flex-wrap: wrap;
			}


			.block-title{
				flex: 1 0 auto;
				margin-right: $spacer * 2;
				max-width: 100%;

				h1{
					border-top: 0;
					color: $primary;
					max-width: 100%;
				}
			}

			.block-actions{
				flex: 1 0 auto;
				margin-bottom: $spacer * 2;

				@include media-breakpoint-up(md) {
					flex: 0 0 auto;
					width: 330px;
					

				}
			}
		}

		.mission-detail{
			display: flex;
			line-height: 1;

			.mission-detail-thumbnail{
				color: $light;
				font-size: rem(26);
				margin-right: 6px;
				flex: 0 0 26px;
			}
			.mission-detail-text{
				.mission-detail-text-title{
					text-transform: uppercase;
					color: $light;
					font-family: $font-family-alt;
					font-size: rem(11);
				}

				.mission-detail-text-content{
					font-weight: 700;
					font-size: rem(13);
				}
			}
		}

		.mission-project_manager{
			line-height: 1;

			.mission-project_manager-title{
				text-transform: uppercase;
				color: $primary;
				font-family: $font-family-alt;
				font-size: rem(11);
				margin-bottom: $spacer;
			}
			.mission-project_manager-thumbnail{
				margin-right: 6px;
				border-radius: 36px;
				width: 36px;
				height: 36px;
				overflow: hidden;
				display: block;
				flex: 0 0 auto;

				img{
					width: 36px;
					height: 36px;
				}
			}
			.mission-project_manager-details{
				.project_manager-name{
					font-family: $font-family-alt;
					font-weight: 700;
					margin-bottom: 4px;
				}

				.project_manager-role,
				.project_manager-contact{
					color: $light;
					font-size: rem(13);
				}
				.project_manager-contact{}
				.project_manager-link{
					margin-top: $spacer;
					a{color: $salmon;}
				}
			}
		}
	}

	&.mission-description{
		.card-body{
			color: $secondary;
		}
	}

	&.mission-files{

		.mission-file{
			display: flex;
			margin-bottom: $spacer;

			&:last-child{
				margin-bottom: 0;
			}

			.mission-file-thumbnail{
				margin-right: 6px;
				font-size: rem(24);
			}

			.mission-file-details{
				line-height: 1.2;

				.mission-file-details-name{
					font-weight: 700;
				}

				.mission-file-details-link{
					a{color: $gray-600;}
				}
			}
		}
	}

	&.experiences{
		font-size: rem(13);
		color: $secondary;

		.experience{
			display: flex;
			padding: $spacer;

			& + .experience{
				border-top: 1px solid $border-color;
				padding-top: $spacer;
			}

			&.has-comment{
				padding-bottom: 0;
			}

			.experience-thumbnail{
				margin-right: $spacer;
				font-size: 0;

				img{
					width: 50px;
					height: 50px;
				}
			}

			.experience-content{
				.experience-content-job{
					font-weight: 700;
					color: $dark;
				}

				.experience-content-company{
					color: $salmon;
					text-transform: uppercase;
					font-weight: 700;
				}

				.experience-content-date{
					color: $light;
					font-size: rem(12);
					margin-bottom: $spacer / 2;
				}

				.experience-content-description{}

				.experience-content-comment{
					.comment-answer{
						margin-right: -1rem;
					}
				}
			}
		}
	}

	&.rendez-vous{
		border: 0;
		box-shadow: 0 4px 40px rgba(0,0,0,.1);

		.card-header{
			background-color: $secondary;
			color: $white;
			font-family: $font-family-base;
			font-size: rem(18);
			font-weight: 400;
			line-height: 1;

			small{
				font-size: rem(11);
				color: $lighter;
			}

			@include media-breakpoint-up(lg) {
				font-size: rem(14);
			}
		}

		.card-body{
			border-left: 1px solid $card-border-color;
			border-right: 1px solid $card-border-color;
		}

		.card-footer{
			border-top: 0;

			.btn{
				border-radius: 0 0 2px 2px;
				padding: .5rem;
			}
		}
	}

	&.acces{
		position: relative;

		.google_map,
		.google_map-container{
			@include media-breakpoint-down(sm) {display: none;} 
		}

		.google_map-container{
			width: 100%;
			min-height: 500px;
		}

		.card-header{
			padding-left: $spacer * 3;
		}

	}

	&.direction{

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 50%;
			margin-top: calc(-323px / 2 + 54px);
			left: $spacer * 3;
			box-shadow: 0 2px 9px 0 rgba(37, 33, 91, 0.07), 0 10px 40px 0 rgba(37, 33, 91, 0.07);
			z-index: 1;
		}

		.card-img-top{
			height: 250px;
			position: relative;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			img{
				width: 100%;
				height: auto;
			}
		}
		
		.card-body{
			position: relative;

			.direction-action{
				position: absolute;
				top: -17px;
				right: $spacer;
				font-size: rem(10);
				color: $salmon;
				text-align: center;
			}

			.direction-name,
			.direction-address{
				line-height: 1.2;
			}

			.direction-address{
				color: $light;

				address{margin-bottom: 0;}
			}
		}

		.card-footer{
			background-color: $lighter;
			color: $secondary;

			.direction-hours,
			.direction-route{
				display: flex;
				font-size: rem(12);

				div:first-child{
					flex: 0 0 30px;
				}
			}
		}
	}

	&.dashboard-overview{
		h3{
			font-weight: 300;
		}

		.brand{
			font-weight: 700;
		}

		.amount{
			font-weight: 700;
			font-size: rem(22);
		}

		.amount,
		small{
			display: block;
			line-height: 1.2;
		}

		&.incomplete .dashboard-overview-data{
			opacity: .3;
		}
	}

	&.dashboard-messages{
		display: flex;
		flex-direction: column;

		.card-header,
		.card-footer{
			flex: 0 0 auto;
		}

		.messagerie{
			flex: 1 0 auto;
			position: relative;
		}

		.card-header + .messagerie{
			.message_list-item:first-child{border-top: 0;}
		}

		.messagerie + .card-footer{
			border-top: 0;
		}
	}

	&.dashboard-missions{
		.missions thead td{
			border-top: 0;
		}
	}

	&.dashboard-notifications{}

	&.dashboard-messages,
	&.dashboard-notifications,
	&.dashboard-missions,
	&.dashboard-mes_demandes,
	&.dashboard-missions_en_cours{

		.card-footer{
			text-align: center;
			font-size: rem(13);
			font-family: $font-family-alt;

			a{
				color: $dark;
			}

			i{
				color: $salmon;
				font-style: normal;
				font-weight: 700;
			}
		}

		.no_item{
			text-align: center;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&.dashboard-announcement{

		&.blue{
			background-color: $blue;
			color: $white;

			.form-group{
				input[disabled]{
					background-color: darken($blue, 10%);
					color: $light;
					border-color: darken($blue, 10%); 
				}
			}

			.btn.disabled{
				background-color: darken($blue, 10%);
				border-color: darken($blue, 10%);
			}
		}

		&.darkgray{
			background-color: $dark;
			color: $white;

			.form-group{
				input[disabled]{
					background-color: $darker;
					color: $light;
					border-color: $darker; 
				}
			}

			.btn.disabled{
				background-color: $darker;
				border-color: $darker;
				color: $secondary;
			}
		}

		h2{
			font-size: rem(24);
			font-weight: 300;

			strong{
				font-weight: 700;
			}
		}

		p{
			font-size: rem(14);
		}

		.announcement-icon{
			font-size: rem(40);
			margin-bottom: $spacer * 2;
		}
	}

	&.dashboard-invite{
		p.lead{
			font-family: $font-family-alt;
		}

		p{margin: 0;}

		a{
			color: $salmon;

			&:hover{
				color: darken($salmon, 10%);
			}
		}
	}

	&.dashboard-missions_en_cours{

		// mission & rdv structure

		.missions_en_cours{
			ul{
				list-style: none;
				margin: 0;
				padding: 0;
			}

			ul.missions_list{

				li.missions_list-item{
					display: flex;
					border: 1px solid $border-color;
					margin-bottom: $spacer;
					border-radius: 4px;
					overflow: hidden;

					&:last-child{
						margin-bottom: 0;
					}

					&.highlight{
						.mission-date{
							background-color: $primary;
						}
					}

				}
			}

			ul.mission-rendez_vous-list{
				width: 100%;

				li.mission-rendez_vous-list-item{
					padding: $spacer / 2;

					& + li.mission-rendez_vous-list-item{
						border-top: 1px solid $border-color;
					}
				}
			}
		}

		// mission

		.mission-date{
			background-color: $light;
			color: $white;
			padding: $spacer / 2;
			line-height: 1.2;
			flex: 0 0 auto;
			width: 62px;
			display: flex;
			align-items: center;
			height: 73px;
			text-align: center;
		}

		// rendez-vous
		.rendez_vous-hour{
			font-weight: 700;
		}

		.rendez_vous-interlocutor,
		.rendez_vous-place{
			font-size: rem(12);
			color: $secondary;
		}
	}

	&.search-result{

		@include transition;

		.user{
			display: flex;
			flex-wrap: wrap;

			.user-thumbnail{
				flex: 0 0 96px;
			}

			.user-thumbnail,
			.user-thumbnail-image{
				width: 96px;
				height: 96px;
			}

			.user-details{
				margin-left: $spacer;
				flex: 1 0 auto;
				width: calc(100% - 96px - #{$spacer});
				padding-top: $spacer / 2;

				.user-details-name,
				.user-details-title{
					@include text-truncate;
				}

				.user-details-name{
					font-family: $font-family-base;
					font-size: rem(16);
				}

				.user-details-title{
					text-transform: uppercase;
					font-size: rem(11);
				}

				.user-details-recommandations{
					margin-top: $spacer / 2;
					display: flex;
					align-items: center;

					.new{
						color: #8BA599;
						font-size: rem(13);
					}

					.recommandations-counter{
						margin-left: $spacer / 2;
						color: $salmon;
						font-size: rem(13);
						display: flex;
						align-items: center;

						.big{
							font-size: rem(16);
							font-weight: 700;
							margin-right: 2px;
						}
					}
				}
			}

			.user-resume{
				font-size: rem(13);
				font-family: $font-family-alt;
				color: $secondary;
				height: 57px;
				margin-top: $spacer;
				overflow: hidden;
				width: 100%;
			}

			.user-tags{
				width: 100%;
				height: 24px;
				overflow: hidden;

				.tag{
					margin-right: 4px;
				}
			}
		}

		.card-footer{
			background-color: lighten($lighter, 2%);
			position: relative;
			min-height: 110px;
			padding: 0;

			.card-footer-display{
				display: flex;

				> div[class*='user-']{
					&:first-child{margin-left: 0;}
					&:last-child{margin-right: 0; border-right: 0;}

					padding: $spacer / 2;
					border-right: 1px solid $lighter;
					flex: 1 0 auto;
					min-height: 110px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.user-rate,
				.user-availability,
				.user-payment{
					text-align: center;

					i{
						font-size: rem(24);
						color: $secondary;
						display: inline-block;
						margin-bottom: $spacer / 2;
					}

					strong{
						font-size: rem(12);
					}

					div{
						line-height: 1;
					}
				}

				.user-rate{

				}

				.user-availability{
					strong{
						color: $salmon;
					}
				}

				.user-payment{

				}
			}

			.card-footer-actions{
				padding: $spacer;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-color: lighten($lighter, 2%);
				@include transition;

				.btn{
					padding: .4rem .8rem;
					font-size: rem(13);
				}
			}
		}

		&:not(.mobile){

			.card-footer-actions {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;
			}

			.card-actions{
				right: -17px;

				.action:not(.check){
					opacity: 0;
					visibility: hidden;
					@include transition;
				}
			}

			&:hover{
				box-shadow: 0 10px 40px rgba(0,0,0,.1);

				.card-actions .action{
					opacity: 1;
					visibility: visible;
				}

				.card-footer-actions{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	// states

	&.select{

		.card-actions{
			.badge-red{
				background-color: $white;
				border: 1px solid $salmon;
				color: $salmon;

				&:hover{
					background-color: darken($salmon, 10%);
					border-color: darken($salmon, 10%);
					color: $white;
				}
			}
		}
	}

	&.selected{
		cursor: default;

		.card-actions{
			.badge-red{
				background-color: $light;
				color: $white;
				border: 0;
				cursor: default;

				&:hover{
					background-color: $light;
					color: $white;
					border: 0;
					cursor: default;
				}
			}
		}

		&:hover{
			box-shadow: none;
		}
	}

	&.selected.selectable{
		.card-actions{
			.badge-red{
				background-color: $salmon;
				border: 1px solid $salmon;
				color: $white;
				cursor: pointer;

				&:hover{
					background-color: darken($salmon, 10%);
					border-color: darken($salmon, 10%);
					color: $white;
				}
			}
		}
	}

	// FOOTER

	.card-footer{
		&.link{
			text-align: center;
			font-size: rem(13);
			font-family: $font-family-alt;

			a{
				color: $dark;
			}

			i{
				color: $salmon;
				font-style: normal;
				font-weight: 700;
			}
		}
	}

}