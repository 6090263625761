// FOOTER

footer{
	&.footer-default{
		background-color: $white;
		color: $secondary;
		@include media-breakpoint-down(sm) {padding: $spacer * 2 0;}
		@include media-breakpoint-up(md) {padding: $spacer * 4 0;}
		

		.logo{
			margin-bottom: $spacer;
		}

		.social{
			a{
				color: $gray-500;
				font-size: rem(24);
				margin-right: $spacer;

				@include hover-focus-active {
					color: $dark;
					text-decoration: none;
				}
			}
		}

		.title{
			color: $dark;
			font-weight: 600;
			margin-bottom: $spacer;
			display: block;
		}

		a.app_link{
			display: inline-block;
			margin-bottom: $spacer / 2;
		}
	}

	&.footer-small{
		background-color: $white;
		color: $secondary;
		@include media-breakpoint-down(sm) {padding: $spacer 0;}
		@include media-breakpoint-up(md) {padding: $spacer * 2 0;}
		text-align: center;
	}
}
