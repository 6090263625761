// MIXINS

@mixin slider_background-image ($colorstart:#F5F5F5, $colorend:#F9F9F9, $backcolor: #F7F7F7) {
  background-color: $backcolor;
  background-image: -moz-linear-gradient(top, $colorstart, $colorend);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($colorstart), to($colorend));
  background-image: -webkit-linear-gradient(top, $colorstart, $colorend);
  background-image: -o-linear-gradient(top, $colorstart, $colorend);
  background-image: linear-gradient(to bottom, $colorstart, $colorend);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorstart}', endColorstr='#{$colorend}',GradientType=0);
}
@mixin slider_box-sizing ($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}
@mixin slider_box-shadow ($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}
@mixin slider_border-radius ($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

// VARIABLES

$slider-line-height: 10px !default;
$slider-border-radius: 4px !default;
$slider-horizontal-width: 100% !default;
$slider-vertical-height: 210px !default;

// Primary colors
$slider-primary: null !default;
@if variable-exists(brand-primary) {
  $slider-primary: $brand-primary !default;
} @else {
  $slider-primary: #0480BE !default;
}

$slider-primary-top: $slider-primary !default;
$slider-primary-bottom: darken($slider-primary, 5%) !default;
$slider-secondary-top: saturate(lighten($slider-primary, 28%), 20%) !default;
$slider-secondary-bottom: saturate(lighten($slider-primary, 23%), 2%) !default;

// grays for slider channel and disabled states
$slider-gray-1: #BEBEBE !default;
$slider-gray-2: #DFDFDF !default;
$slider-gray-3: #E5E5E5 !default;
$slider-gray-4: #E9E9E9 !default;
$slider-gray-5: #F5F5F5 !default;
$slider-gray-6: #F9F9F9 !default;

// unicode color for demo page
$slider-unicode-color: #726204 !default;

// RULES

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &.slider-horizontal {
    width: $slider-horizontal-width;
    height: $slider-line-height;
    .slider-track {
      height: $slider-line-height/2;
      width: 100%;
      margin-top: -$slider-line-height/4;
      top:  50%;
      left: 0;
    }
    .slider-selection, .slider-track-low, .slider-track-high {
      height: 100%;
      top: 0;
      bottom: 0;
    }
    .slider-tick,
    .slider-handle {
      margin-left: -$slider-line-height/2;
      &.triangle {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        border-width: 0 $slider-line-height/2 $slider-line-height/2 $slider-line-height/2;
        width: 0;
        height: 0;
        border-bottom-color: $slider-primary-bottom;
        margin-top: 0;
      }
    }
    .slider-tick-container {
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .slider-tick-label-container {
      white-space: nowrap;
      margin-top: $slider-line-height;
      .slider-tick-label {
        display: inline-block;
        padding-top: $slider-line-height * 1.2;
        text-align: center;
      }
    }
    &.slider-rtl {
      .slider-track {
        left: initial;
        right: 0;
      }
      .slider-tick,
      .slider-handle {
        margin-left: initial;
        margin-right: -$slider-line-height/2;
      }
      .slider-tick-container {
        left: initial;
        right: 0;
      }
    }
  }
  &.slider-vertical {
    height: $slider-vertical-height;
    width: $slider-line-height;
    .slider-track {
      width: $slider-line-height/2;
      height: 100%;
      left: 25%;
      top: 0;
    }
    .slider-selection {
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .slider-track-low, .slider-track-high {
      width: 100%;
      left: 0;
      right: 0;
    }
    .slider-tick,
    .slider-handle {
      margin-top: -$slider-line-height/2;
      &.triangle {
        border-width: $slider-line-height/2 0 $slider-line-height/2 $slider-line-height/2;
        width:  1px;
        height: 1px;
        border-left-color: $slider-primary-bottom;
        margin-left: 0;
      }
    }
    .slider-tick-label-container {
      white-space: nowrap;
      .slider-tick-label {
        padding-left: $slider-line-height * .2;
      }
    }
    &.slider-rtl {
      .slider-track {
        left: initial;
        right: 25%;
      }
      .slider-selection {
        left: initial;
        right: 0;
      }
      .slider-tick,
      .slider-handle {
        &.triangle {
          border-width: $slider-line-height/2 $slider-line-height/2 $slider-line-height/2 0;
        }
      }
      .slider-tick-label-container {
        .slider-tick-label {
          padding-left: initial;
          padding-right: $slider-line-height * .2;
        }
      }
    }
  }
  &.slider-disabled {
    .slider-handle {
      @include slider_background-image($slider-gray-2, $slider-gray-1, mix($slider-gray-2, $slider-gray-1));
    }
    .slider-track {
      @include slider_background-image($slider-gray-3, $slider-gray-4, mix($slider-gray-3, $slider-gray-4));
      cursor: not-allowed;
    }
  }
  input {
    display: none;
  }
  .tooltip-inner {
    white-space: nowrap;
    max-width: none;
  }
  .tooltip {
    &.top {
      margin-top: -36px;
    }
  }

  .tooltip-inner {
    white-space: nowrap;
    max-width: none;
  }

  .hide {
    display: none;
  }
}

.slider-track {
  @include slider_background-image($slider-gray-5, $slider-gray-6, mix($slider-gray-5, $slider-gray-6));
  @include slider_box-shadow(inset 0 1px 2px rgba(0,0,0,0.1));
  @include slider_border-radius($slider-border-radius);

  position: absolute;
  cursor: pointer;
}

.slider-selection {
  @include slider_background-image($slider-gray-6, $slider-gray-5, mix($slider-gray-6, $slider-gray-5));
  @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
  @include slider_box-sizing(border-box);
  @include slider_border-radius($slider-border-radius);

  position: absolute;
}
.slider-selection.tick-slider-selection {
  @include slider_background-image($slider-secondary-top, $slider-secondary-bottom, mix($slider-secondary-top, $slider-secondary-bottom));
}

.slider-track-low, .slider-track-high {
  @include slider_box-sizing(border-box);
  @include slider_border-radius($slider-border-radius);

  position: absolute;
  background: transparent;
}

.slider-handle {
  @include slider_background-image($slider-primary-top, $slider-primary-bottom, mix($slider-primary-top, $slider-primary-bottom));
  @include slider_box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05));

  position: absolute;
  top: 0;
  width:  $slider-line-height;
  height: $slider-line-height;
  background-color: $slider-primary;
  border: 0px solid transparent;

  &.round {
    @include slider_border-radius($slider-line-height);
  }
  &.triangle {
    background: transparent none;
  }
  &.custom {
    background: transparent none;
    &::before{
      line-height: $slider-line-height;
      font-size: 20px;
      content: '\2605'; //unicode star character
      color: $slider-unicode-color;
    }
  }
}

.slider-tick {
  @include slider_background-image($slider-gray-5, $slider-gray-6, mix($slider-gray-5, $slider-gray-6));
  @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
  @include slider_box-sizing(border-box);

  position: absolute;
  width: $slider-line-height;
  height: $slider-line-height;
  filter: none;
  opacity: 0.8;
  border: 0px solid transparent;

  &.round {
    border-radius: 50%;
  }
  &.triangle {
    background: transparent none;
  }
  &.custom {
    background: transparent none;
    &::before {
      line-height: $slider-line-height;
      font-size: 20px;
      content: '\2605'; //unicode star character
      color: $slider-unicode-color;
    }
  }
  &.in-selection {
    @include slider_background-image($slider-secondary-top, $slider-secondary-bottom, mix($slider-secondary-top, $slider-secondary-bottom));
    opacity: 1;
  }
}
