/**
 *   Here's where everything gets included. You don't need
 *   to change anything here, and doing so might break
 *   stuff. Here be dragons and all that.
 */

// UNSLIDER VARIABLES

/**
 *   Default variables
 *
 *   While these can be set with JavaScript, it's probably
 *   better and faster to just set them here, compile to
 *   CSS and include that instead to use some of that
 *   hardware-accelerated goodness.
 */
 
//  Unslider 2 has navigation styles pre-designed. You can turn it on or off here. Default is off (false).
$unslider-dot-navigation: true;
$unslider-dot-colour: $salmon;

//  Unslider 2 has navigation styles pre-designed. You can turn it off here.
$unslider-transition-function: cubic-bezier(.42,0,.58,1);

// Set a namespace for Unslider
$unslider-namespace: 'unslider';


// UNSLIDER RESET

.#{$unslider-namespace}, %#{$unslider-namespace} {
	//  Should either be relative or absolute
	//  as long as it's not static, but we'll
	//  set it using jQuery
	// position: relative;
	overflow: auto;
	margin: 0;
	padding: 0;

	&-wrap {
		position: relative;
		
		&.unslider-carousel > li {
			float: left;
		}
	}

	//  Vertical sliders don't float left
	&-vertical {
		> ul {
			height: 100%;
		}

		li {
			float: none;
			width: 100%;
		}
	}

	//  Fading needs everything to appear on top of
	//  each other
	&-fade {
		position: relative;

		.unslider-wrap li {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			z-index: 8;

			&.unslider-active {
				z-index: 10;
			}
		}
	}
	
	ul, ol, li {
		list-style: none;
		
		/* Reset any weird spacing */
		margin: 0;
		padding: 0;
		
		border: none;
	}

	&-arrow {
		position: absolute;
		left: 20px;
		z-index: 2;

		cursor: pointer;

		&.next {
			left: auto;
			right: 20px;
		}
	}
}

// UNSLIDER DOTS

@if($unslider-dot-navigation){
	.#{$unslider-namespace}-nav, %#{$unslider-namespace}-nav {
		ol {
			list-style: none;
			text-align: center;

			li {
				display: inline-block;
				width: 10px;
				height: 10px;
				margin: 0 8px;

				background: $gray-400;
				border-radius: 10px;

				overflow: hidden;
				text-indent: -999em;

				border: 2px solid white;

				cursor: pointer;
				box-sizing: content-box;

				&.unslider-active {
					background: $unslider-dot-colour;
					border-color: $unslider-dot-colour;
					cursor: default;
				}
			}
		}
	}
}
