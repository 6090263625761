// HAS-ICON

div[class*='col']{
	&.has-icon{
		position: relative;

		&::after{
			font-family: 'jobaround';
			position: absolute;
			font-size: rem(22);
			color: $light;
			right: $spacer;
			top: -6px;
		}

		&.icon-calendar{
			&::after{
				content: "\e900";
			}
		}
	}
}