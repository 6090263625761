// Beta

	.beta{
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h4{
			text-transform: uppercase;
			color: $primary;
			font-size: rem(16);
			font-weight: 700;
			letter-spacing: .2em;
		}

		hr{
			width: 80px;
			background-color: $primary;
			margin: $spacer auto $spacer * 2;
		}

		h1{
			font-family: $font-family-script;
			line-height: 2em;
			font-size: rem(30);
			margin-bottom: $spacer * 2;
		}

		p{
			color: $secondary
		}
	}