.forgotten_password{
	$hero-height-sm: 295px;
	$hero-height-md: 295px;
	$footer-height-sm: 53px;
	$footer-height-md: 85px;

	@include media-breakpoint-down(sm) {
		min-height: calc(100vh - #{$hero-height-sm} - #{$footer-height-sm});
	}

	@include media-breakpoint-up(md) {
		min-height: calc(100vh - #{$hero-height-md} - #{$footer-height-md});
	}
}