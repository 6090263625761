// TABLES JOBAROUND

.table-jobaround {
	font-family: $font-family-alt;
	letter-spacing: 0;

	// general

	thead,
	.thead {
		font-family: $font-family-alt;
		color: $light;
		font-weight: normal;
		text-transform: uppercase;
		font-size: rem(10);
	}

	thead th {border-bottom-width: 1px;}

	.thead,
	.tbody {
		>.row.no-gutters {
			border-bottom: 1px solid $table-border-color;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.muted {
		background-color: $lighter;
		font-size: rem(12);
		font-weight: 700;
		padding: $table-cell-padding;
	}

	.table-cell {
		padding: $table-cell-padding;
	}

	// vertical align

	&.v-align-middle {
		th,
		td {
			vertical-align: middle;
		}

		.table-user {
			align-items: center;

			.table-user-thumbnail {
				display: inline-flex;
			}
		}
	}

	// table responsive

	tr.row {
		margin: 0;
		display: flex;
		flex-wrap: nowrap;
	}

	// customs colors

	.debit,
	.red {
		color: $red;
	}

	.credit,
	.green {
		color: $green;
	}

	.salmon {
		color: $salmon;
	}

	.kaki {
		color: $kaki;
	}

	// CUSTOM ELEMENTS

	// disabled

	.disabled {
		color: $light;
		background-color: lighten($lighter, 2%);

		.btn {
			background-color: $light;
			border-color: $light;
			color: $white;
		}
	}

	// tables-user

	.table-user {
		display: flex;
	}

	.table-user-details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		line-height: 1.2;
		margin-left: $spacer / 2;
		font-family: $font-family-base;
		font-weight: 700;

		.table-user-title {
			color: $light;
			text-transform: uppercase;
			font-size: rem(10);
		}

		.table-user-name {}

		.table-user-role {
			color: $primary;
			font-family: $font-family-alt;
			font-weight: 400;
		}

		.table-user-query {
			color: $light;
			font-size: rem(12);
		}

		.table-user-conversation {
			a {
				color: $dark;
				font-size: rem(12);
				margin-top: $spacer;
				display: inline-block;

				@include hover-focus-active {
					color: $dark;
				}
			}
		}
	}

	.table-user-list {
		font-size: 0;
		padding-left: 16px;

		.table-user-list-item,
		.table-user-thumbnail {
			display: inline-block;
			margin-left: -16px;
		}
	}

	.table-date {
		font-family: $font-family-base;
		font-weight: 700;
		font-size: rem(12);

		i {
			font-size: rem(22);
			display: inline-block;
			vertical-align: middle;
			color: $light;
			position: relative;
			top: -4px;
		}

		.day {
			margin-left: 4px;
			color: $dark;
		}

		.hour {
			color: $primary;
		}
	}

	.table-location {
		color: $light;
		font-size: rem(12);

		i {
			font-size: rem(22);
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: -2px;
		}
	}

	.table-quotation {
		line-height: 1.2;

		small {
			text-transform: uppercase;
			color: $light;
			font-size: rem(9);
		}

		.quotation {
			font-size: rem(18);
			font-weight: 700;
		}

		.link {
			font-size: rem(10);

			a {
				color: $lavender;
				font-weight: 700;
			}
		}
	}

	.table-demande {
		display: flex;

		.table-demande-details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			line-height: 1.2;
			margin-left: $spacer / 2;
			font-family: $font-family-base;
			font-weight: 700;

			.table-demande-name {
				&.devis {
					strong {color: $lavender;}
				}

				&.modifiee {
					strong {color: $salmon;}
				}

				&.reservation {
					strong {color: $primary;}
				}
			}

			.table-demande-prestation {
				font-size: rem(12);
				color: $light;
			}

			.table-demande-conversation {
				a {
					color: $dark;
					font-size: rem(12);
					margin-top: $spacer;
					display: inline-block;
				}
			}
		}
	}

	.table-rating {
		margin-top: $spacer / 2;
		text-align: center;
	}
}

// table header border in card adjustments

.card-header + .table-jobaround {
	thead th {
		border-top: 0;
	}
}

.card > .table-jobaround:first-child thead th {
	border-top: 0;
}
