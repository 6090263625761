// MODIFY BUTTON

.modify{
	position: absolute;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	background-color: $yellow;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	top: $spacer * 2;
	right: -15px;
	@include transition;
	cursor: pointer;

	@include hover-focus-active {
		background-color: darken($yellow, 10%);
	}
}