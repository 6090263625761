.comments{
	color: $secondary;
	font-family: $font-family-alt;
	font-size: rem(13);
	line-height: 1.8;

	.comment-title,
	.comment-answer-title{
		font-weight: 700;
		font-family: $font-family-base;
	}

	.comment-answer{
		border-top: 1px solid $table-border-color;
		background-color: $lighter;
		padding: $spacer;
		margin-top: $spacer;

		@include media-breakpoint-up(md) {margin-right: -$table-cell-padding;}

		.comment-answer-user{
			display: flex;
			align-items: center;
			margin-bottom: $spacer;

			.comment-user-thumbnail{
				width: 25px;
				height: 25px;
				border-radius: 25px;
				overflow: hidden;
				font-size: 0;
				margin-right: 6px;

				img{
					max-width: 100%;
					max-height: 100%
				}
			}

			.comment-user-details{
				line-height: 1.4;
				
				.user-details-name{
					color: $dark;
					font-weight: 700;
					font-family: $font-family-base;
				}

				.user-details-company{
					text-transform: uppercase;
					font-size: rem(12);
				}
			}

		}
	}
}