.hero-home-slider{
	overflow: hidden;
	z-index: $zindex-hero-home-slider;
	border: 0 !important;
	border-radius: 0 !important;
	background-color: $darker;
	position: relative;
	margin: 0 !important;

	ul.slides{

		li{
			@include media-breakpoint-only(xs) {height: 550px;}
			@include media-breakpoint-up(sm) {height: 520px;}
			@include media-breakpoint-up(lg) {height: 650px;}
			@include media-breakpoint-up(xl) {height: 650px;}
			position: relative;

			.slider-picture{
				height: 100%;
				background-size: cover;
				background-position: center;

				&::before{
					content: '';
					display: block;
					position: absolute;
					background-color: $dark;
					opacity: .3;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}

			.slider-title{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				text-align: center;

				@include media-breakpoint-down(sm) {
					bottom: 250px;
				}
				@include media-breakpoint-up(md) {
					bottom: 250px;
				}
				@include media-breakpoint-up(lg) {
					top: 200px;
					bottom: 235px;
				}
				@include media-breakpoint-up(xl) {
					bottom: 235px;
				}

				h1{
					font-family: $font-family-script;
					line-height: 1.5em;
					@include media-breakpoint-only(xs) {font-size: rem(20);}
					@include media-breakpoint-up(sm) {font-size: rem(26);}
					@include media-breakpoint-up(md) {font-size: rem(28);}
					@include media-breakpoint-up(xl) {font-size: rem(35);}
				}

				h2{
					font-size: rem(20);
					font-weight: 400;
					@include media-breakpoint-only(xs) {font-size: rem(16);}
					@include media-breakpoint-up(sm) {font-size: rem(20);}
				}
			}
		}
	}


	a.flex-next,
	a.flex-prev{
		background-color: $white;
		border-radius: 40px;
		font-size: 0;

		&::before{
			font-family: "jobaround" !important;
			font-size: 14px !important;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	a.flex-next{
		@include media-breakpoint-down(sm) {right: -10px !important;}
		&::before{
			content: "\f061" !important;
		}
	}

	a.flex-prev{
		@include media-breakpoint-down(sm) {left: -10px !important;}
		&::before{
			content: "\f060" !important;
		}
	}

}


//temp fix
.b2c{
	.hero-home-slider{
		.slider-picture{
			background-position: center top !important;
		}
	}
}
