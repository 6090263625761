// USER DETAILS

.user-details{
	line-height: 1.2;
	position: relative;

	.user-details-name{
		font-family: $font-family-alt;
		font-weight: 700;
	}

	.user-details-role,
	.user-details-title,
	.user-details-place,
	.user-details-company,
	small.text-muted{
		color: $secondary;
		font-size: rem(12);
		//white-space: nowrap;
		//text-overflow: ellipsis;
		//overflow: hidden;
	}

	.user-details-link{
		font-size: rem(12);

		a{
			color: $salmon;

			@include hover-focus-active {
				color: darken($salmon, 10%);
			}
		}
	}
}