body > .overlay{
	background-color: rgba(0,0,0,.4);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $zindex-home-overlay;
	transition: all .2s ease-out;

	opacity: 0;
	visibility: hidden;

	&.display{
		opacity: 1;
		visibility: visible;
		transition: all .5s ease-out;
	}
}