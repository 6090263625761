// user group

ul.user-group{
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 3px;

	li.user-group-item{
		display: inline-block;
		width: 15px;
		height: 15px;
		overflow: hidden;
		margin: 1px;
		border-radius: 15px;

		img{
			max-width: 100%;
			max-height: 100%;
		}

		&.more{
			display: inline-flex;
			font-size: rem(8);
			color: $white;
			background-color: $dark;
			align-items: center;
			justify-content: center;
			line-height: 0;
			width: 15px;
			height: 15px;
			border-radius: 16px;
		}
	}
}