#search_autocomplete{
	position: relative;

	.ui-autocomplete{
		padding: $spacer 0 !important;

		@include media-breakpoint-down(md) {
			width: 100% !important;
		}

		@include media-breakpoint-up(lg) {
			width: calc(100% - 70px - 160px) !important;
		}

		&.ui-widget-content{
			display: flex;
			border: 0 !important;
			border-radius: 0 0 4px 4px !important;

			@include media-breakpoint-down(sm) {
				flex-wrap: wrap;
			}
			@include media-breakpoint-up(md) {

			}
		}

		.ui-menu-item{
			flex: 1 1 33.333%;
			color: $secondary;
			padding: 0 $spacer !important; 
			border: 1px solid transparent !important;
			cursor: default !important;

			.ui-menu-item{
				padding: 0 !important;

				a{
					padding: $spacer / 2 !important;
					cursor: pointer !important;
					@include transition;
				}

				&.ui-state-focus{
					background-color: $lighter !important;
				}
			}

			&:first-child{
				border-left: 0;
			}

			.col-title{
				text-transform: uppercase;
				font-size: rem(11);
				padding: 0 0 $spacer / 2 $spacer / 2;
			}

			strong{
				color: $primary;
				font-weight: 400;
			}

		//states

		&.ui-state-focus{
			background: none !important;
		}

		&.ui-state-active,
		&.ui-state-focus{
			margin: 0 !important;
		}
	}

	> .ui-menu-item{
		@include media-breakpoint-down(sm) {
			flex: 1 0 100%;
			width: 100%;
			margin-bottom: $spacer;
		}
	}

	.ui-menu{
		.ui-state-focus,
		.ui-state-active{
			margin: 0 !important;
		}
	}

	.ui-state-active,
	.ui-widget-content .ui-state-active{
		background-color: $white !important;
		color: $darker !important;
		border: 1px solid transparent !important;

		&.col-title{
			color: $secondary !important;
		}
	}

	.ui-state-active{
		a,
		a:link,
		a:visited{
			color: $darker !important;
		}

		a:hover{
			background-color: $lighter !important;
		}
	}

	.col1,
	.col2,
	.col3{
		border-left: 1px solid $border-color !important;
		@include media-breakpoint-up(md) {
			display: block;
			width: 33.333%;
			float: left;
		}
		
	}


	// prestataire

	.prestataire-item {
		display: flex;

		.prestataire-item-thumbnail {
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 40px;
			overflow: hidden;
			flex: 0 0 40px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.prestataire-item-details {
			flex: 1 0 auto;
			padding-left: 8px;

			.prestataire-name {
				display: block;
				font-size: rem(14);
				color: $dark;
			}

			.prestataire-role {
				display: block;
				font-family: $font-family-alt;
				font-size: rem(12);
				color: $secondary;
			}
		}
	}
}

}


