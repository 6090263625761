header{

	// default header structure

	&.default-header:not(.scrolled){

		.separator{
			opacity: 0;
		}

		@include media-breakpoint-up(lg) {
			padding-top: $spacer * 2;
			background-color: transparent;
		}
	}

	&.default-header.scroll{
		position: absolute;
		background-color: $darker;
		padding-top: 0;
		top: 0;
		left: 0;
		width: 100%;
	}

	&.default-header{

		@include media-breakpoint-down(md) {
			.container{
				width: 100%;
				max-width: inherit;
			}

			.header-content{
				height: 50px;
			}
		}

		.separator{
			opacity: 1;
			@include transition;
		}

		@include transition;

		.header-content{

			@include media-breakpoint-down(md) {
				display: flex;
				align-items: center;

				&:first-child{
					justify-content: flex-start;
				}

				&:last-child{
					justify-content: flex-end;
				}
				
				.header-content-wrapper{
				}
			}

			@include media-breakpoint-up(lg) {

				&:first-child{
					display: none;
				}

				.header-content-wrapper{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					width: 100%;

					.separator{
						width: 1px;
						height: 70px;
						background-color: $dark;
					}
				}

			}

			.search-mobile{
				a{
					color: $white;

					@include hover-focus-active {
						color: $lighter;
					}
				}
			}
		}

		.logo{
			position: relative;
			height: 50px;
			align-self: center;

			img{
				position: relative;
				//top: -8px;
			}
		}
	}

	// dashboard header structure

	&.dashboard-header{

		@include media-breakpoint-down(md) {
			position: relative;
			flex-wrap: wrap;
			height: calc(#{$header-height-mobile * 2});

			.header-content{
				&.mobile{
					display: flex;
					align-items: center;
					width: 100%;
					height: $header-height-mobile;
					justify-content: space-between;
					padding-left: $header-height-mobile;
					background-color: $white;
					color: $darker;
				}

				&.desktop{
					.logo{
						display: none;
					}

					.header-content-title{
						margin: 0 0 0 calc(#{$header-height-mobile} + #{$spacer});
					}
				}

				.search-mobile{
					a{
						color:$darker;

						@include hover-focus-active {
							color: $dark;
						}
					}
					position: relative;

					.search-content{
						position: absolute;
						top: 0;
						right: 0;
						height: 50px;
						width: 100vw;
						background-color: $white;	
						z-index: 1032;
						padding-left: 12px;
						padding-right: 12px;
						display: flex;
						align-items: center;
						opacity: 0;
						visibility: hidden;
						@include transition;

						.form-control, .btn{
							padding: .4rem;
							height: 36px;
						}

						&.active{
							top: 50px;
							opacity: 1;
							visibility: visible;
						}	
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			i.icon-logo{
				background-color: $primary;
				width: $header-height-desktop;
				height: $header-height-desktop;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: rem(26);
			}

			.logo{
				width: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $yellow;
			}

			.header-content{
				&.mobile{display: none;}
				&.desktop{display: flex;}
			}
		}
	}

	// default & dashboard headers common

	&.default-header,
	&.dashboard-header{
		background-color: $darker;
		color: $white;
		display: flex;
		align-items: center;

		@include media-breakpoint-down(md) {

			.container{
				padding: 0;

				.row{
					margin: 0;

					.col-12{
						padding: 0;
					}
				}
			}

			.header-content{
				height: $header-height-mobile;
			}
		}

		@include media-breakpoint-up(lg) {
			height: $header-height-desktop;

			.header-content{
				height: $header-height-desktop;
			}
		}

		.header-content{
			width: 100%;
			display: flex;
			
			.header-content-title{
				flex: 1 0 auto;
				display: flex;
				align-items: center;
				margin: 0 $spacer * 2;

				h1{
					font-weight: 100;
					font-size: rem(18);
					margin: 0;

					strong{
						font-weight: 400;
					}
				}
			}
		}

		.logo{
			flex: 0 0 auto;
			display: flex;
			align-items: center;

			@include media-breakpoint-down(md) {
				min-width: 140px;
				justify-content: center;

				picture{
					font-size: 0;
				}

				img{
					max-width: 100%;
					height: auto;
				}
			}
		}

		.search-mobile{
			height: $header-height-mobile;
			width: $header-height-mobile;
			display: flex;
			align-items: center;
			justify-content: center;

			a{
				text-decoration: none;
			}
		}
	}

	// search

	.search{
		position: relative;
		height: 100%;
		display: flex;
		min-width: 330px;
		height: 70px;

		.prepend,
		.form-control{
			background-color: lighten($darker, 3%);
		}

		.prepend{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 $spacer / 2 0 $spacer * 3/2;
			font-size: rem(26);
			color: $light;
		}

		.form-control{
			font-size: rem(12);
			font-family: $font-family-base;
			border: 0;
			border-radius: 0;
			padding: $spacer;
			color: $white;

			&::placeholder {
				color: $light;
				opacity: .6;
			}

			&:focus{box-shadow: none;}
		}
	}

	// notifications

	.notifications{
		display: flex;
		flex: 0 0 auto;

		.messages,
		.notification{
			display: flex;
			align-items: center;
			transition: border-color .2s ease-in-out;
			position: relative;

			i{
				font-size: rem(22);
				position: relative;
			}

			&.has-new{

				> i::after{
					content: '';
					position: absolute;
					width: 7px;
					height: 7px;
					background-color: $yellow;
					border-radius: 10px;
					top: 1px;
					right: 1px;
				}
			}
		}

		.messages{
			padding: $spacer * 3/2 $spacer / 2 $spacer * 3/2 $spacer * 3/2;
			border-left: 1px solid transparent;
			cursor: pointer;

			&:hover{
				.user-messages_nav{
					opacity: 1;
					visibility: visible;
					top: 60px;
				}
			}
		}

		.notification{
			padding: $spacer * 3/2 $spacer * 3/2 $spacer * 3/2 $spacer / 2;
			border-right: 1px solid transparent;
			cursor: pointer;

			&:hover{
				.user-notifications_nav{
					opacity: 1;
					visibility: visible;
					top: 60px;
				}
			}
		}
	}

	// user menu

	.user-menu{

		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
			left: 0;
			min-height: 100%;
			z-index: $zindex-user-menu;

			.user-menu-wrapper{
				display: flex;
				align-items: center;
				background-color: $darker;
				color: $white;
			}

			.nav-user-menu-mobile-trigger,
			.nav-user-menu-mobile-close{
				cursor: pointer;
			}

			.nav-user-menu-mobile-trigger{
				font-size: rem(22);
				padding: $spacer / 2 0;
				height: $header-height-mobile;
				width: $header-height-mobile;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.nav-user-menu-mobile-close{
				flex : 0 0 30px;
				display: none;
			}

			&.active{
				width: 300px;

				.nav-user-menu-mobile-trigger{
					display: none;
				}

				.nav-user-menu-mobile-close{
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: rem(16);
					padding: $spacer / 2;
				}

				.user-menu-wrapper{
					padding: $spacer / 2 0;

					.user-thumbnail{
						display: inline-block;
					}

					.user-details{
						display: inline-block;
						flex: 1 0 auto;

						.user-name::before{
							display: none;
						}
					}
				}

				.user-main_nav{
					visibility: visible;
					opacity: 1;
					left: 0;
				}
			}

			.user-thumbnail,
			.user-details{
				display: none;
			}

			.user-thumbnail{
				margin-left: $spacer / 2;
			}

			.user-main_nav{
				height: 100%;
				left: -300px;
				opacity: 0;
				visibility: hidden;
			}
		}

		@include media-breakpoint-up(lg) {
			position: relative;
			padding: $spacer 0;
			margin: 0 $spacer * 2;
			display: flex;
			align-items: center;

			&:hover{
				.user-main_nav{
					opacity: 1;
					visibility: visible;
					top: 58px;
				}
			}

			.nav-user-menu-mobile-trigger,
			.nav-user-menu-mobile-close{
				display: none;
			}

			.user-menu-wrapper{
				display: flex;
				align-items: center;
			}
		}

		.user-thumbnail{
			width: 34px;
			height: 34px;

			img{
				width: 34px;
				height: 34px;
				border-radius: 34px;
			}
		}
		.user-details{
			margin-left: $spacer / 3;
			min-height: inherit;

			.user-name{
				font-size: rem(12);
				display: inline-block;
				max-width: 200px;
				@include text-truncate;
				position: relative;
				padding-right: $spacer;

				&:before{
					position: absolute;
					content: "\f107";
					font-family: 'jobaround';
					margin-left: $spacer / 2;
					right: 0;
					top: 2px;
				}
			}

			.user-status{
				color: $light;
				text-transform: uppercase;
				font-size: rem(8);
				font-family: $font-family-alt;
			}
		}
	}

	// user menu mobile

	//.user-menu-mobile{
	//	position: relative;
//
	//	i{
	//		font-size: rem(22);
	//	}
//
	//	.user-main_nav{
	//		left: -16px;
	//		right: inherit;
//
	//		.user-main_nav-wrapper{
	//			&::before{
	//				left: 16px;
	//				right: inherit;
	//			}
	//		}
	//	}
//
	//	&:hover{
	//		.user-main_nav{
	//			opacity: 1;
	//			visibility: visible;
	//			top: 58px;
	//		}
	//	}
//
	//}

}


.header-placeholder{
	height: 70px;
	display: block;
	width: 100%;
}