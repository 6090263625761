.tooltip{
	margin-left: -20px;

	&:after{
		content:'';
		display: inline;
		position: absolute;
		border: 6px solid transparent;
		border-top-color: $secondary;
		left: 50%;
		margin-left: -6px;
	}
}

.slider-handle{
	background-color: $white;
	background-image: none;
	box-shadow: 0 2px 10px rgba(0,0,0,.3);
}

.slider-selection{
	background-color: $secondary;
	background-image: none;
}

.slider-track-low,
.slider-track-high{
	background-color: $light;
}

.tooltip-min,
.tooltip-max,
.tooltip-main{
	min-width: 40px;
	font-size: rem(10);

	.tooltip-inner{
		background-color: $secondary;
	}
}