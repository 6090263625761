// register

#register,
#connect{
	padding: 0;

	display: flex;
	flex-direction: column;

	.content{
		flex: 0 0 auto;
		padding: $spacer * 2;

		h1,
		h2{
			text-align: center;
			margin: 0;
		}

		h2{
			font-size: rem(13);
			font-weight: 300;
			color: $secondary;
			margin: -.4em 0 0;
		}

		hr{
			margin-left: -$spacer * 2;
			margin-right: -$spacer * 2;
		}

		@include media-breakpoint-down(sm) {
			padding-top: calc(58px + #{$spacer * 2});

			h1{
				font-family: $font-family-base;
				font-size: rem(18);
			}

			h2{
				display: none;
			}
		}

		@include media-breakpoint-up(md) {
			h1{
				font-family: $font-family-script;
				font-size: rem(24);
				line-height: 2em;
			}
		}

		.connect{
			color: $light;
			font-size: rem(13);

			a{
				font-weight: 700;
			}
		}
	}

	.register-footer{
		flex: 1 0 auto;
		background-color: $dark;
		color: $secondary;
		font-size: rem(12);
		padding: $spacer * 2;

		.connect{
			font-family: $font-family-alt;
		}

		h2{
			color: $white;
			font-size: rem(18);

			span{
				color: $light;
				font-size: rem(12);
			}
		}
	}
}