.hero{

	&.hero-home{
		color: $white;
		position: relative;

		&::before{
			content: '';
			display: block;
			position: absolute;
			background-color: $dark;
			opacity: .3;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&.no-overlay::before{
			display: none;
		}

		.hero-content{
			position: relative;
			text-align: center;
			@include media-breakpoint-only(xs) {height: 550px;}
			@include media-breakpoint-up(sm) {height: 520px;}
			@include media-breakpoint-up(lg) {height: 650px;}
			@include media-breakpoint-up(xl) {height: 650px;}
		}

		.hero-content-title{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 0 $spacer * 2;

			h1{
				line-height: 1.5;
			}

			h2{
				font-family: $font-family-script;
				line-height: .5;

				.big{
					font-size: 1.5em;
				}
			}

			@include media-breakpoint-down(sm) {bottom: 250px;}
			@include media-breakpoint-up(md) {bottom: 250px;}
			@include media-breakpoint-up(lg) {bottom: 235px; top: 200px;}

			@include media-breakpoint-only(xs) {
				h1{font-size: rem(20); margin-left: -2em;}
				h2{font-size: rem(18); margin-left: 6em;}
			}

			@include media-breakpoint-only(sm) {
				h1{font-size: rem(24);margin-left: -2.5em;}
				h2{font-size: rem(20);margin-left: 5.5em;}
			}

			@include media-breakpoint-only(md) {
				h1{font-size: rem(28);margin-left: -3.5em;}
				h2{font-size: rem(22);margin-left: 5em;}
			}

			@include media-breakpoint-up(lg) {
				h1{font-size: rem(30);margin-left: -2em;}
				h2{font-size: rem(24);margin-left: 8em;}
			}
		}

		&.univers-sport{
			.hero-content-title{
				margin-left: 0;

				h2{
					margin-left: 0;
				}

				p{
					font-size: rem(18);
					margin-top: $spacer * 3;
					font-weight: 200;

					strong{
						font-weight: 700;
					}

				}
			}
		}

		.searchbar-container{
			position: absolute;
			z-index: $zindex-hero-home-search;
			bottom: 0;
			width: 80%;
			left: 10%;

			@include media-breakpoint-up(lg) {
				bottom: 100px;
			}

			.searchbar{
				@extend .container;
				padding: 0;
			}
		}

		.story_pop{
			@include media-breakpoint-up(lg) {right: 100px; bottom: 40px;}
		}

	}

	&.hero-prismic{
		background-size: cover;
		min-height: 215px;
		padding: $spacer * 4 $spacer $spacer * 2;
		position: relative;

		&::before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(29, 40, 62, .400);
		}

		.hero-content{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 1;
			padding: $spacer * 2 0;

			.hero-content-title{
				color: $white;
				text-align: center;
			}
		}

		.script{
			color: $primary;
		}
	}

	&.hero-b2b,
	&.hero-b2c{
		position: relative;
		background-size: cover;
		min-height: 215px;

		@include media-breakpoint-down(md) {
			margin-bottom: $spacer * 4;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: $spacer * 5;
		}

		&::before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(29, 40, 62, .400);
		}

		.searchbar{
			position: relative;
			top: 100px;
			z-index: 3;
			box-shadow: 0 8px 30px rgba(0,0,0,.1);
			border-radius: 4px;

			@include media-breakpoint-down(md) {
				top: 0;
			}

			@include media-breakpoint-up(lg) {
				top: 100px;
			}
		}

		.hero-content{
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: calc(100% - #{$spacer} * 4);
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			margin-top: $spacer * 4;
			padding: $spacer * 4 $spacer;
		}
	}

	&.hero-b2b{
		@include background-retina('../img/background/bg-business.jpg', '../img/background/bg-business@2x.jpg');
	}

	&.hero-service,
	&.hero-b2c,
	&.hero-prismic{
		@include background-retina('../img/background/bg-service.jpg', '../img/background/bg-service@2x.jpg');
	}

	&.hero-freelance,
	&.hero-entreprise,
	&.hero-prestataire,
	&.hero-service{
		color: $white;
		position: relative;

		&::before{
			content: '';
			display: block;
			position: absolute;
			background-color: $dark;
			opacity: .3;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		@include media-breakpoint-down(md) {
			padding-top: 7rem;
		}

		@include media-breakpoint-only(lg) {
			max-height: 575px;
			padding-top: 14rem;
		}

		@include media-breakpoint-up(xl) {
			max-height: 605px;
			padding-top: 16rem;
		}
	}

	// SEE MORE

	.see_more{
		position: absolute;
		bottom: -25px;
		left: 50%;
		width: 50px;
		height: 50px;
		margin-left: -25px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;
		border-radius: 50px;
		box-shadow: 0 2px 20px rgba(0,0,0,.2);
		color: $secondary;
		z-index: 3;
		@include transition;

		@include hover-focus-active {
			text-decoration: none;
			box-shadow: 0 2px 30px rgba(0,0,0,.4);
		}
	}
}
