.calendar{
	position: relative;
	min-height: 200px;

	.message{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: rgba(255,255,255,.8);
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		min-height: 200px;
		display: none;
		border-radius: 0 0 3px 3px;

		> div[class*='message-']{
			display: none;
		}

		&.no_service{
			display: flex;
			.message-no_service{display: block;}
		}

		&.no_date{
			top: 118px;
			display: flex;
			min-height: 82px;
			.message-no_date{display: block;}
		}

		&.loading_day,
		&.loading_slots,
		&.loading{
			display: flex;
			.message-loading{display: block;}
		}

		&.no_available_slots{
			display: flex;
			top: 118px;
			min-height: 82px;
			.message-no_available_slots{display: block;}
		}
	}

	.calendar-months{
		.form-group{
			margin-bottom: 0;

			.custom-select{
				border: 0;
				background-color: $lighter;
				border-bottom: 1px solid $border-color;
				border-radius: 0;
			}

		}
	}

	.calendar-days{
		position: relative;
		text-align: center;
		margin: 0 $spacer;
		width: calc(100% - #{$spacer} * 2);

		.day-name{
			text-transform: uppercase;
			font-size: rem(10);
			color: $light;
			letter-spacing: 0;
			font-family: $font-family-alt;
			padding-top: $spacer;
		}

		.day-number{
			font-size: rem(12);
			padding-bottom: $spacer;
		}

		ul.slides{
			li{
				cursor: pointer;

				&:hover{
					background-color: $lighter;
				}

				&.active{
					background-color: $primary;

					.day-name,
					.day-number{
						color: $white;
					}
				}
			}
		}
	}

	.calendar-hours{

		ul{
			list-style: none;
			margin: 0;
			padding: 0;

			li{
				padding: $spacer / 2 $spacer;
				border-bottom: 1px solid $border-color;
				position: relative;
				font-size: rem(12);
				color: $secondary;
				cursor: pointer;
				@include transition;

				&:first-child{
					border-top: 1px solid $border-color;
				}

				&:hover{
					background-color: $lighter;
					color: $dark;
				}

				&.active{
					color: $dark;
					background-color: $lighter;
					font-weight: 700;

					&::after{
						content: '';
						display: block;
						position: absolute;
						width: 6px;
						height: 6px;
						top: 50%;
						margin-top: -3px;
						right: calc(#{$spacer} + 1px + 3px);
						background-color: $white;
						border-radius: 6px;

					}

					&::before{
						background-color: $primary;
						border-color: $primary;
					}
				}

				&::before{
					content: '';
					display: inline-block;
					width: 14px;
					height: 14px;
					border-radius: 14px;
					border: 1px solid $light;
					position: absolute;
					right: $spacer;
					top: 50%;
					margin-top: -7px;
				}
			}
		}
	}

	.custom-navigation{
		position: absolute;
		top: 0;
		display: flex;
		justify-content: space-between;
		width: calc(100% + #{$spacer} * 2);
		left: -$spacer;
		right: -$spacer;

		a[class^='flex-']{
			flex: 0 0 20px;
			padding: 1.4rem 0;
		}
	}
}