aside.sidebar{
	&.sidebar-demande_devis{

		@include media-breakpoint-up(lg) {
			padding-top: 118px;
			background-color: $white;
		}

		@include media-breakpoint-up(xl) {
			padding-left: 4%;
			padding-right: 4%;
		}

		.title{
			font-weight: 700;
		}

		.answer{
			display: flex;
			color: $secondary;
			align-items: center;
			margin-bottom: $spacer / 2;

			.answer-icon{
				font-size: rem(36);
			}

			.answer-text{
				margin-left: $spacer / 2;
				line-height: 1.2;
			}
		}

		.confirmation{
			line-height: 1.4;
			margin-bottom: $spacer * 2;

			.confirmation-summary{
				color: $secondary;
				font-family: $font-family-alt;
			}
		}

		.answer + .confirmation{
			margin-top: $spacer * 2;
		}

		.btn{
			padding: $spacer / 2;
		}
	}

	&.sidebar-proposition{
		background-color: $lighter;

			@include media-breakpoint-down(md) {
				padding: $spacer;
			}

			@include media-breakpoint-up(lg) {
				padding: 67px $spacer * 2 $spacer * 2;
				//min-height: calc(100% + #{$spacer * 2});
				min-height: 100%;
			}
	}

	&.sidebar-reservation,
	&.sidebar-prestation{
		background-color: $lighter;

			@include media-breakpoint-down(md) {
				padding: $spacer;
			}

			@include media-breakpoint-up(lg) {
				padding: 67px $spacer * 2 $spacer * 2;
				//min-height: calc(100% + #{$spacer * 2});
				min-height: 100%;
			}

			.reservation-date{
				padding: $spacer 0;
				border-bottom: 1px solid $border-color;
				display: flex;
				align-items: center;
				line-height: 1.2;

				.reservation-date-hour{
					font-family: $font-family-alt;
					font-size: rem(32);
					font-weight: 700;
				}

				.reservation-date-day{
					color: $secondary;
					margin-left: 6px;
					font-size: rem(12);
					font-family: $font-family-alt;
				}
			}

			.reservation-service,
			.reservation-total,
			.reservation-commission{
				font-weight: 300;
				padding: $spacer 0;
				border-bottom: 1px solid $border-color;
				display: flex;
				justify-content: space-between;

				strong{
					font-weight: 700;
				}
			}

			.reservation-commission{
				div div{
					max-width: 80%;
				}
			}

			.reservation-cancel{
				padding: $spacer * 2 0 0;
			}

			.reservation-cancel,
			.reservation-sap{
				color: $secondary;
				font-size: rem(12);
				font-family: $font-family-alt;
				margin-bottom: $spacer * 2;
			}

			.reservation-action{
				margin-top: $spacer * 2;
			}
	}
}