// MODULES

@import 'modules/add-element';
@import 'modules/alert';
@import 'modules/badge';
@import 'modules/block-title';
@import 'modules/calendar';
@import 'modules/categories';
@import 'modules/checklist';
@import 'modules/colors';
@import 'modules/comments';
@import 'modules/display-map';
@import 'modules/filters';
@import 'modules/has-delete';
@import 'modules/has-icon';
@import 'modules/modify-button';
@import 'modules/mission-card';
@import 'modules/new-message';
@import 'modules/notification';
@import 'modules/or';
@import 'modules/overlay';
@import 'modules/overview-icon';
@import 'modules/payment';
@import 'modules/payment-method';
@import 'modules/pellet';
@import 'modules/photo';
@import 'modules/quicknav';
@import 'modules/recipients';
@import 'modules/status';
@import 'modules/searchbar';
@import 'modules/sidebar';
@import 'modules/slider-home';
@import 'modules/story-pop';
@import 'modules/stripe';
@import 'modules/tag';
@import 'modules/upload-modify-button';
@import 'modules/user-details';
@import 'modules/user-group';
@import 'modules/user-list';
@import 'modules/user-thumbnail';

.rating{
	color: $yellow;
}

ul.prestations-list{
	list-style: none;
	margin: 0;
	padding: 0;

	li.prestations-list-item{
		padding: $spacer / 2 0;
		border-bottom: 1px solid $border-color;

		&:last-child{
			border-bottom: 0;
			padding-bottom: 0;
		}
		
		.prestation-name{
			font-weight: 700;
		}

		.prestation-details{
			text-transform: uppercase;
			font-size: rem(10);
			font-weight: 700;

			.prestation-details-duration{
				color: $secondary;

				.prestation-details-trigger{
					color: $salmon;
					display: inline-block;

					@include hover-focus-active {
						color: darken($salmon, 10%)
					}
				}
			}

			.prestation-details-text{
				text-transform: none;
				margin-top: $spacer / 2;
				font-size: rem(13);
				color: $secondary;
				font-family: $font-family-alt;
				font-weight: normal;
			}
		}

		.prestation-price,
		.prestation-check{
			display: inline-block;
		}

		.prestation-price{
			font-weight: 700;
			font-size: rem(18);
		}

		.prestation-check{}
	}
}

// recommandation

.recommendation{
	.recommendation-user{
		display: flex;
		align-items: center;

		.recommendation-user-thumbnail{
			width: 40px;
			height: 40px;

			img{
				width: 40px;
				height: 40px;
				border-radius: 40px;

			}
		}

		.recommendation-user-details{
			margin-left: 6px;
			line-height: 1.2;

			.recommendation-user-details-name{
				font-family: $font-family-alt;
				font-weight: 700;
			}
			.recommendation-user-details-company{
				color: $secondary;
				text-transform: uppercase;
				font-size: rem(12);
			}
			.recommendation-user-details-date{
				color: $secondary;
				font-size: rem(12);
			}
		}
	}

	.recommendation-rating{
		margin-top: $spacer / 2;
	}

	.recommendation-title,
	.recommendation-message{
		font-size: rem(13);
		color: $secondary;
		margin-top: $spacer / 2;
	}

	.recommendation-title{
		font-weight: 700;
	}

	.recommendation-message{
		font-family: $font-family-alt;
	}
}

// formations
.formations{
	font-family: $font-family-alt;

	.formation-school{
		font-weight: 700;
	}

	.formation-date{
		color: $secondary;
		font-size: rem(10);
	}

	.formation-title{
		color: $secondary;
	}
}


// breadcrumb

nav.breadcrumb{
	padding: 0 0 1rem 0;
	border-bottom: 1px solid $border-color;
	background-color: $body-bg;
	font-size: rem(13);

	a{
		color: $secondary;
	}
}