.display-map-button{
	background-color: $primary;
	color: $white;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: $spacer * 2;
	right: $spacer * 2;
	border-radius: 50px;
	font-size: rem(24);
	cursor: pointer;
	z-index: $zindex-displaymap;
	@include transition(background-color .3s ease-in-out);


	@include hover-focus-active {
		background-color: darken($primary, 10%);
	}
}