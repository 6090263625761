.block-title{
	font-family: $font-family-alt;
	position: relative;
	padding: 0 0 $spacer 0;
	margin: $spacer * 2 0;

	h5{
		font-size: rem(14);
		font-weight: bold;
	}

	h1{
		font-size: rem(22);
		font-family: $font-family-base;
		border-top: 1px solid $lighter;
		padding: $spacer 0 0;
		margin-bottom: 0;
		font-weight: 400;
	}

	h6{
		font-size: rem(10);
		text-transform: uppercase;
		color: $secondary;
		margin: 0;
	}

	h6+h1{
		padding-top: 0;
	}

	small{
		line-height: 1.2em;
		display: inline-block;
	}

	&::before,
	&::after{
		display: block;
		content:'';
		position: absolute;
	}

	&::before{
		background-color: $lighter;
		height: 1px;
		width: 100%;
		bottom: 0;
	}

	&::after{
		background-color: $primary;
		height: 3px;
		width: 10%;
		bottom: -1px;
	}
}

aside.sidebar{
	.block-title{
		&::before{
			background-color: $border-color;
		}
	}
}