// STATUS

.status{
	background-color: $lighter;
	display: inline-block;
	color: $secondary;
	padding: .2rem .4rem;
	font-size: rem(12);
	font-weight: normal;
	border-radius: 4px;

	&.status-green{
		background-color: $green;
		color: $white;
	}

	&.status-primary{
		background-color: $primary;
		color: $white;
	}

	&.status-blue{
		background-color: $blue;
		color: $white;
	}

	&.status-red{
		background-color: $red;
		color: $white;
	}

	&.status-salmon{
		background-color: $salmon;
		color: $white;
	}

	&.status-secondary{
		background-color: $secondary;
		color: $white;
	}
}