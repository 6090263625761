// ADD ELEMENT

// add element button

.add-element.btn{
	font-weight: 700;
	color: $secondary;
	padding: $btn-padding-x-sm $btn-padding-y-sm * 3;
	border-radius: 2rem;
	cursor: pointer;
	transition: background-color .3s ease-out;
	text-transform: none;

	i{
		color: $yellow;
		margin-right: $spacer;
	}

	&:hover{
		background-color: $lighter;
	}
}

// add element card

.add-element.card{
	margin-bottom: $spacer * 2;
	border: 1px dashed $light;
	background-color: transparent;
	color: $light;
	transition: box-shadow .3s ease-out;
	@include border-radius;

	&.added{
		border: 1px solid $border-color;
	}

	&:hover{
		box-shadow: 0 5px 20px rgba(0,0,0,.2);

		.card-img-top .modify{
			opacity: 1;
			visibility: visible;
		}
	}

	.card-img-top{
		position: relative;
		width: 100%;
		padding-bottom: 86%;
		background-color: $lighter;

		.modify{
			opacity: 0;
			visibility: hidden;
		}

		.img-top-container{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			> a{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
			}

			img{
				max-width: 100%;
				max-height: 100%;
			}
		}

		.icon-container{
			font-size: rem(24);
			background-color: $light;
			display: flex;
			width: 40px;
			height: 40px;
			border-radius: 40px;
			color: $white;
			align-items: center;
			justify-content: center;
			margin-bottom: $spacer / 2;
		}
	}

	&.has-description{
		.card-img-top{
			padding-bottom: 58%;
		}
	}

	.card-body{
		padding: 0;

		.file-description{
			color: $dark;
			font-weight: 600;
			padding: $spacer $spacer 0;
		}

		.file-date{
			font-size: rem(12);
			color: $secondary;
			padding: 0 $spacer $spacer;
		}
	}

}