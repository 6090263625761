// PAYMENT

// expiration

.expiration{
	display: flex;
	align-items: center;

	.label{
		margin: 0 $spacer * 2 $spacer $spacer;
		@include media-breakpoint-only(xs) {
			margin: 0 $spacer $spacer 0;
		}
	}

	.form-inline{
		display: flex;
		margin-bottom: $spacer;

		.form-group{
			flex: 1 1 50%;

			input:focus{
				z-index: 1;
			}

			&:first-child{
				.form-control{
					border-right: 1px solid $body-bg;
					border-radius: 4px 0 0 4px;
				}
			}

			&:last-child{
				.form-control{
					border-left: 0;
					border-radius: 0 4px 4px 0;
				}
			}
		}
	}
}