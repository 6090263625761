.quicknav{
	position: relative;

	&:hover{
		ul{
			opacity: 1;
			visibility: visible;
		}
	}

	.quicknav-trigger{
		padding: 0 $spacer / 2;
	}

	ul{
		margin: 0;
		padding: 0;
		list-style: none;
		background-color: $white;
		box-shadow: 0 1px 2px rgba(0,0,0,.1), 0 10px 40px rgba(0,0,0,.2);
		border: 1px solid $border-color;
		border-radius: 4px;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 100%;
		opacity: 0;
		visibility: hidden;
		@include transition;

		li{
			display: block;

			a{
				display: block;
				padding: $spacer / 3 $spacer;
				@include transition;
			}

			&:first-child a{
				padding-top: $spacer / 2;
			}

			&:last-child a{
				padding-bottom: $spacer / 2;
			}

			&:hover a{
				background-color: $lighter;
				text-decoration: none;
			}
		}
	}

}