// MESSAGERIE

.messagerie{

	// layout

	.msg-left{
		@include media-breakpoint-up(lg) {
			min-height: calc(100vh - #{$header-height-desktop} - #{$subnav-height-desktop}); 
		}
	}

	.msg-right{
		@include media-breakpoint-down(md) {
			position: absolute;
			top: $header-height-mobile * 2;
			left: 0;
			min-height: 100vh;
			width: 100vw;
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			display: none;
			@include transition;

			&.active{
				opacity: 1;
				visibility: visible;
				display: block;
			}
		}

		@include media-breakpoint-up(lg) {
			position: relative;
		}
	}

	// message list

	ul.message_list{
		list-style: none;
		margin: 0;
		padding: 0;

		li.message_list-item{
			padding: $spacer;
			border-bottom: 1px solid $border-color;
			cursor: pointer;
			transition: background-color .3s ease-in-out;

			&:first-child{
				border-top: 1px solid $border-color;
			}

			&:hover{
				background-color: $white;
			}

			&.active{
				background-color: $white;
			}

			.preview_message{
				display: flex;

				.preview_message-author{
					width: 34px;
					height: 34px;
					flex: 0 0 auto;

					img{
						border-radius: 34px;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.preview_message-details{
					line-height: 1.2;
					padding: 0 $spacer;
					color: $secondary;
					flex: 1 1 auto;

					.preview_message-details-user-name{
						font-weight: 700;
						color: $dark;
					}
					.preview_message-details-subject{
						font-size: rem(13);
					}
					.preview_message-details-excerpt{
						font-size: rem(12);
					}
					.preview_message-details-date{
						font-size: rem(10);
					}
				}

				.preview_message-status{
					flex: 0 0 auto;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.preview_message-status-date{
						font-size: rem(12);
						color: $light;
					}
				}
			}
		}
	}

	// message wrapper

	.message-wrapper{
		background-color: $white;
		font-family: $font-family-alt;

		display: flex;
		flex-direction: column;
		height: 100%;

		@include media-breakpoint-down(md) {
			padding: $spacer * 1;
		}
		
		@include media-breakpoint-up(lg) {
			padding: $spacer * 2;
		}

		.message-wrapper-header{
			.header-title{
				font-size: rem(16);
			}

			.header-type{
				font-size: rem(12);

				span{
					color: $primary;
				}
			}

			.header-status{
				font-family: $font-family-base;
				font-size: rem(12);
				font-weight: 700;
			}
		}

		.message-wrapper-overview{
			background-color: $gray-300;

			@include media-breakpoint-down(md) {
				padding: $spacer * 1;
				margin-left: -$spacer;
				margin-right: -$spacer;
				margin-top: $spacer;
			}

			@include media-breakpoint-up(lg) {
				border-left: 6px solid $light;
				padding: $spacer * 2;
				padding-left: $spacer * 4;
				margin-top: $spacer * 2;
			}

			.block-title{
				margin: 0 0 $spacer;

				&::before{
					background-color: $hr-border-color;
				}

				&::after{
					background-color: $dark;
				}
			}

			.actions{
				margin-top: 2.4rem;

				.actions-group{
					display: flex;

					.actions-group-item{
						flex: 1 0 calc(33.333% - 8px);
						margin: 0 4px;

						&:first-child{margin-left: 0;}
						&:last-child{margin-right: 0;}
					}
				}

				.btn-action{
					background-color: $secondary;
					color: $white;
					font-family: $font-family-base;
					font-size: rem(10);
					font-weight: 400;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: $spacer / 2;
					margin: 0 4px;
					height: 60px;

					i{
						font-size: rem(20);
						margin-bottom: 2px;
					}

					&:first-child{margin-left: 0;}
					&:last-child{margin-right: 0;}

					@include hover-focus-active {
						background-color: darken($secondary, 10%);
					}
				}
			}

			.overview-date{
				.overview-date-hour{
					font-family: $font-family-alt;
					font-weight: 700;
					font-size: rem(24);
				}
				.overview-date-day{}
			}

			.overview-price{}

			.overview-details{
				.overview-details-type{
					text-transform: uppercase;
					font-size: rem(9);
					color: $secondary;
				}

				.overview-details-content{
					font-size: rem(12);
					font-weight: 700;
				}
			}
		}

		.message-wrapper-content{
			overflow-y: auto;
			padding-bottom: 173px;
		}

		.message-wrapper-actions{
			background-color: $white;
			position: fixed;
			bottom: 0;
			right: 0;
			z-index: $zindex-dashboard-main_nav - 1;

			.message-area{
				@include media-breakpoint-down(md) {
					textarea{
						padding: $spacer / 2;
					}
				}
			}

			.message-ui{
				display: flex;
				align-items: center;

				.ui-upload{
					flex: 0 0 auto;
					display: flex;
					align-items: center;

					.ui-upload-file,
					.ui-upload-image{
						flex: 0 0 auto;

						input{
							display: none;
						}

						label{
							width: 34px;
							height: 34px;
							border: 1px solid $input-border-color;
							border-radius: $input-border-radius;
							padding: 2px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 0 4px 0 0;
						}
					}

				}

				.ui-join,
				.ui-send{
					@include media-breakpoint-down(md) {
						.btn{
							padding: $spacer / 3 $spacer / 2;
						}
					}
				}

				.ui-join{
					flex: 0 0 auto;
					margin-right: 4px;

					.btn{
						@include media-breakpoint-down(md) {
							border: 1px solid $input-border-color;
							border-radius: $input-border-radius;

							i{
								margin-right: 4px;
							}
						}
						

					}
				}

				.ui-send{
					flex: 1 0 auto;
					text-align: right;
				}
			}

			@include media-breakpoint-down(md) {
				width: 100%;
				padding: $spacer;
			}

			@include media-breakpoint-up(lg) {
				width: calc(100% - #{$dashboard-main_nav-width-dektop} + 24px - 33.3333%);
				padding: $spacer;
			}
		}
	}

	// message

	.message{
		margin-top: $spacer;

		.message-layout{
			display: flex;

			.message-layout-1{
				//flex: 1 0 auto;
			}
			.message-layout-2{
				//flex: 0 0 66px;
			}

			.message-author-name{}

			.message-author-thumbnail{
				width: 34px;
				height: 34px;
				border-radius: 34px;
				overflow: hidden;

				img{
					max-width: 100%;
					max-height: 100%;
				}
			}

			.message-author-name{
				font-weight: 700;
			}

			.message-text{
				color: $secondary;
				padding: $spacer;
				border-radius: 6px;
				margin-top: $spacer;
				font-family: $font-family-alt;
				font-weight: 300;
			}

			.message-time{
				margin-top: $spacer / 2;
				font-size: rem(12);
				color: $light;
			}

			.image-container,
			.file-container{
				margin: $spacer 0;
			}

			.image-container{
				img{
					max-width: 100%;
				}
			}

			.file-container{
				&::before{
					font-family: 'jobaround';
					content: "\e91f";
					font-size: rem(20);
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		&.right{

			.message-layout{
				text-align: right;
				padding-left: $spacer * 3;
				justify-content: flex-end;

				.message-layout-1{
					order: 1;

					.message-text{
						text-align: left;
						background-color: $gray-200;
					}
				}

				.message-layout-2{
					order: 2;
					padding-left: $spacer * 2;
				}
			}
		}

		&.left{
			.message-layout{
				padding-right: $spacer * 3;

				.message-layout-1{
					order: 2;

					.message-text{
						border: 1px solid $light;
					}
				}

				.message-layout-2{
					order: 1;
					padding-right: $spacer * 2;
				}
			}
		}

	}

	// no message selected

	.message-no_message_selected{
		padding: $spacer;
		color: $secondary;
		text-align: center;

		i{
			font-size: rem(30);
		}

		h4{
			font-size: rem(18);
		}
	}


}