
// Main search bar

aside.searchbar{
	display: flex;
	width: 100%;
	background-color: $white;
	border-radius: 4px;

	// overlay triggered

	&.has-overlay{
		position: relative;
		z-index: $zindex-searchbar;
	}

	@include media-breakpoint-down(md) {
		margin-top: $spacer * 4;
		margin-bottom: $spacer * 3;
		flex-wrap: wrap;

		.search{
			display: none;
		}

		.form-group,
		.btn{
			width: 100%;
		}

		label i{
			display: none;
		}

		.form-group{
			position: relative;

			&.service{
				input{
					border-radius: 4px 4px 0 0;
				}
			}

			i[class^="icon-"]{
				position: absolute;
				color: $secondary;
				font-size: rem(20);
				z-index: $zindex-searchbar-icon;
				top: 15px;
				left: 15px;
			}

			label{
				left: 60px;
			}

			input{
				padding-left: 60px;
			}
		}

		.btn{
			border-radius: 0 0 4px 4px;
		}
	}

	@include media-breakpoint-up(lg) {
		margin-top: $spacer * 5;
		margin-bottom: $spacer * 4;

		.search{
			background-color: $primary;
			border-radius: 4px 0 0 4px;
			width: 70px;
			height: 70px;
			font-size: rem(26);
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 70px;
			color: $white;
			position: relative;

			&::after{
				content:'';
				position: absolute;
				border: 8px solid transparent;
				border-left-color: $primary; 
				right: -16px;
				top: calc(50% - 8px);
				z-index: $zindex-searchbar + 2;

			}
		}

		.form-group{
			height: 70px;

			> i{display: none;}

			label{
				height: 34px;
				display: flex;
				align-items: center;
				z-index: $zindex-searchbar + 3;

				i{
					margin-right: 4px;
				}
			}

			&.input-filled{
				label{
					height: 24px;
					margin-bottom: 0;
				}
			}

			input{
				height: 70px;
			}

			&.service{
				flex: 1 0 auto;
			}

			&.location{
				flex: 0 0 250px;
				input{background-color: #f9f5f3;}
			}

			&.date{
				flex: 0 0 140px;
			}
		}

		.btn{
			flex: 0 0 160px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0 4px 4px 0;
			z-index: $zindex-searchbar + 2;
		}
	}

	.form-group{
		margin-bottom: 0;

		input{
			border: none;
			border-radius: 0;

			@include hover-focus-active {
				position: relative;
				z-index: 104;
			}
		}
	}
}

// contextual

.dashboard .searchbar{
	margin: $spacer * 2 0;

	.form-group.location input{
		background-color: $white;
	}
}

// Page search bar

.page-search{
	@include media-breakpoint-down(sm) {
		input.form-control,
		.input-group-append .input-group-text{
			padding: $spacer / 2;
		}

		.form-control-label{
			top: .7rem;
			left: .7rem;
		}
	}
}