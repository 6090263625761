.alert-jobaround{
	background-color: $white;
	display: flex;
	border: 1px solid $border-color;
	position: relative;

	.alert-icon{
		color: $white;
		position: absolute;
		left: -1px;
		top: -1px;
		height: calc(100% + 2px);
		width: 50px;
		border-radius: $border-radius 0 0 $border-radius;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem(26);

		& + .alert-message{
			margin-left: 50px;
		}
	}

	.alert-message{
		color: $dark;
		padding: $spacer;

		.pellet{
			width: 8px;
			height: 8px;
			display: inline-block;
		}
	}

	@each $color, $value in $theme-colors {
			&.#{$color} {
				.alert-icon{background-color: $value;}
				.alert-message strong{
					color: $value;
				}
			}
		}
}