// Jobaround notification

// page notification

.page-notification{
	position: fixed;
	right: $spacer;
	bottom: $spacer;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 10px 40px rgba(0, 0, 0, 0.2);
	background-color: $white;
	@include border-radius;
	padding: $spacer * 3 / 2;
	transition: background-color .2s ease-in-out;

	@include hover-focus-active {
		background-color: $lighter;
		cursor: pointer;
	}

	.page-notification-close{
		position: absolute;
		right: 0;
		top: 0;
		font-size: rem(12);
		color: $light;
		@include transition;
		padding: $spacer / 2;
		cursor: pointer;

		@include hover-focus-active {
			color: $secondary;
		}
	}
}


// standalone notification

.jobaround-notification{
	display: flex;
	font-size: rem(13);
	color: $dark;
	transition: background-color .3s ease-in-out;

	&:hover{
		color: $dark;
		text-decoration: none;
		background-color: $lighter;

		.notification-details-comment{
			background-color: darken($lighter, 3%) !important;
		}
	}

	.notification-thumbnail{
		width: 36px;
		height: 36px;
		overflow: hidden;
		border-radius: 36px;
		flex: 0 0 auto;

		img{
			width: 36px;
			height: 36px;
		}
	}

	.notification-details{
		line-height: 1.2;
		padding-left: $spacer;
		flex: 1 0 200px;

		.notification-details-title{}

		.notification-details-type{
			color: $secondary;
		}

		.notification-details-date{
			color: $light;
			margin-top: $spacer / 2;
			font-size: rem(10);
		}

		.notification-details-comment{
			background-color: $lighter;
			color: $light;
			margin-top: $spacer;
			padding: $spacer / 2;
			font-size: rem(11);
			transition: background-color .3s ease-in-out;
			@include border-radius;
		}

		.notification-details-excerpt{
			color: $light;
			font-size: rem(11);
			transition: background-color .3s ease-in-out;
			font-style: italic;
			@include border-radius;
		}
	}

	.notification-icon{
		padding-left: $spacer;
		font-size: rem(22);
		color: $light;
	}
}