// DASHBOARD OVERVIEW ICONS

.overview-icon{
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	color: $white;
	font-size: rem(18);
	display: flex;
	align-items: center;
	justify-content: center;

	&.positive{
		background-color: $green;

		&::after{
			font-family: 'jobaround';
			content: "\e915";
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.star{
		background-color: $yellow;

		&::after{
			font-family: 'jobaround';
			content: "\f123";
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.testimonial{
		background-color: $green;

		&::after{
			font-family: 'jobaround';
			content: "\f087";
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.teal{
		background-color: #01e1cd;
	}

	&.yellow{
		background-color: $yellow;
	}

	&.light{
		background-color: $light;
	}

	&.blue{
		background-color: $blue;
	}

	&.cyan{
		background-color: $cyan;
	}
}