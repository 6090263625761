@font-face {
    font-family:'Luna';
    src: url('../fonts/Luna.eot');
    src: url('../fonts/Luna.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Luna.woff2') format('woff2'),
        url('../fonts/Luna.woff') format('woff'),
        url('../fonts/Luna.svg#Luna') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00F7;
}

@font-face {
    font-family:'Quicksand';
    src: url('../fonts/QuicksandRegular.eot');
    src: url('../fonts/QuicksandRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuicksandRegular.woff2') format('woff2'),
        url('../fonts/QuicksandRegular.woff') format('woff'),
        url('../fonts/QuicksandRegular.svg#Quicksand-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Quicksand';
    src: url('../fonts/QuicksandBold.eot');
	src: url('../fonts/QuicksandBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/QuicksandBold.woff2') format('woff2'),
		url('../fonts/QuicksandBold.woff') format('woff'),
		url('../fonts/QuicksandBold.svg#Quicksand-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Quicksand';
    src: url('../fonts/QuicksandMedium.eot');
    src: url('../fonts/QuicksandMedium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuicksandMedium.woff2') format('woff2'),
        url('../fonts/QuicksandMedium.woff') format('woff'),
        url('../fonts/QuicksandMedium.svg#Quicksand-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Quicksand';
    src: url('../fonts/QuicksandLight.eot');
    src: url('../fonts/QuicksandLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/QuicksandLight.woff2') format('woff2'),
        url('../fonts/QuicksandLight.woff') format('woff'),
        url('../fonts/QuicksandLight.svg#Quicksand-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');