.side-popup{

	// DEVIS

	.proposition,
	.payment{
		position: relative;

		&:hover{
			.delete{
				opacity: 1;
			}
		}
	}

	.proposition{
		&::before{
			content: '';
			margin: $spacer 0 $spacer * 2;
			display: block;
			background-color: $lighter;
			height: 1px;
		}
	}

	.payment{
		.delete{
			top: 0;
		}
	}

	.delete{
		position: absolute;
		cursor: pointer;
		padding: 15px 10px;
		@include transition;

		@include media-breakpoint-down(sm) {
			top: 50px;
			right: 0;
			z-index: 2;
		}

		@include media-breakpoint-up(md) {
			top: 50px;
			right: -30px;
		}

		&:hover{
			color: $primary;
		}
	}

	.add_proposition,
	.add_payment{
		margin-top: $spacer;

		.btn.btn-lighter{
			border: 1px dashed $light;
			color: $secondary;
		}
	}

	.summary{
		margin-top: $spacer * 4;
		text-transform: uppercase;

		.tva{
			color: $secondary;
		}

		.total,
		.total_ttc{
			font-weight: 700;
		}
	}

	// DEMANDE ENVOYEE

	&.request{
		background-color: $primary;

		main.content{
			width: 100%;
		}

		.request-content{
			text-align: center;

			@include media-breakpoint-down(xs) {padding: 0 $spacer;}
			@include media-breakpoint-only(sm) {padding: 0 10%;}
			@include media-breakpoint-up(md) {padding: 0 25%;}

			h1{
				font-size: rem(24);
			}

			h1.script{
				color: $white;
			}

			p{
				color: $white;
			}
		}

		.request-header{
			position: relative;

			.sent{
				width: 100%;
			}

			.envelope{
				position: absolute;
				left: 50%;
				bottom: 0;
				margin-left: -54px;
			}

			.plane{
				position: absolute;
				left: 50%;
				bottom: 0;
				margin-left: -39px;
			}
		}
	}

	// Beta

	.beta{
		min-height: calc(100vh - 4rem);
	}
}

// COLOR VARIANTS

body.b2b{
	.side-popup{
		&.request{
			.request-content{
				h1{
					color: $white;
				}
			}
		}
	}
}

body.b2c{
	.side-popup{

	}
}

