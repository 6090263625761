// LAYOUT

html{
	width: 100%;
	height: 100%;
}

body{
	position: relative;
}

// headers & navs layout

.dashboard-main_nav{
	position: fixed;
}

@include media-breakpoint-down(md) {
	.dashboard-header{

	}

	.dashboard-main_nav{
		position: absolute;
		top: $header-height-mobile;
		left: 0;
	}

	.content-wrapper{
		margin: $spacer;

		&.agenda,
		&.messagerie,
		&.profil{
			margin: 0;
		}

		&.has-project-header{
			margin-top: 0;
			margin-left: 0;
			margin-right: 0;

			.dashboard-project-container{
				margin: $spacer;
			}
		}

		.section-title{
			display: none;
		}
	}
}

@include media-breakpoint-up(lg) {
	.dashboard-header,
	.default-header{
		position: fixed;
		z-index: $zindex-default-header;
		top: 0;
		left: 0;
		width: 100%;
		//z-index: 1080;
	}

	.dashboard-main_nav,
	.dashboard-sub_nav{
		top: $header-height-desktop;
	}

	.dashboard-sub_nav{
		position: fixed;
		z-index: $zindex-sub-nav;
		left: $header-height-desktop;
		width: calc(100vw - #{$header-height-desktop});
	}

	.dashboard-agenda_nav,
	.dashboard-messagerie_nav,
	.dashboard-breadcrumb{
		position: absolute;
		top: -$subnav-height-desktop;
		left: $header-height-desktop;
		width: calc(100% - #{$header-height-desktop});
	}

	.content-wrapper{
		margin-top: calc(#{$header-height-desktop * 1} + #{$spacer * 2});
		margin-left: calc(#{$header-height-desktop} + #{$spacer * 2});
		margin-right: $spacer * 2;
		margin-bottom: $spacer * 2;

		&.has-subnav{
			margin-top: calc(#{$header-height-desktop * 2} + #{$spacer * 2});
		}

		&.has-sidebar{
			@include media-breakpoint-up(lg) {
				margin-right: $grid-gutter-width / 2;
				margin-bottom: 0;

				.dashboard {
					.sidebar{
						min-height: calc(100vh - #{$header-height-desktop});
					}

					.col-lg-8{
						padding-right: $spacer * 2;
					}
				}
			}
		}

		&.has-project-header{
			margin-top: $header-height-desktop;
			margin-left: $header-height-desktop;
			margin-right: 0;

			.dashboard-project-container{
				margin: $spacer * 2;
			}
		}

		&.inscription{
			margin-right: 0;
			margin-bottom: 0;
		}

		&.agenda,
		&.messagerie{
			margin-top: calc(#{$header-height-desktop} + 84px);
			margin-left: $header-height-desktop;
			margin-right: 0;
			margin-bottom: 0;
		}

		&.profil{
			margin: 0;
		}
	}
}

// main content

.main-content{

}

// side content
.side-content{
	background-color: $white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include transition();

	.close-btn{
		opacity: 0;
	}

	&.display {
		.close-btn{
			opacity: 1;
		}
	}

	&.side-content-small{

		@include media-breakpoint-down(sm) {
			width: 100vw;
			margin-left: 100vw;

			&.display{margin-left: 0;}
		}

		@include media-breakpoint-up(md) {
			width: 450px;
			margin-left: calc(100vw + 450px);

			&.display{margin-left: calc(100vw - 450px);}
		}

		@include media-breakpoint-up(lg) {
			width: 450px;
			margin-left: calc(100vw + 450px);

			&.display{margin-left: calc(100vw - 450px);}
		}

		@include media-breakpoint-up(xl) {
			width: 450px;
			margin-left: calc(100vw + 450px);

			&.display{margin-left: calc(100vw - 450px);}
		}

	}

	.close-btn{
		@include media-breakpoint-down(sm) {
			top: .7rem;
			margin-left: $spacer;
		}

		@include media-breakpoint-up(md) {
			top: 2.6em;
			margin-left: -17px;
		}
	}
}

// sidebars

aside.sidebar{
	@include media-breakpoint-up(lg) {
		margin-top: - $spacer * 2;
		padding-top: $spacer;
	}
}

// section titles

.section-title,
.section-subtitle{
	margin: 0 0 $spacer $spacer * 2;
	font-weight: 300;

	strong{
		font-weight: 500;
	}
}

.section-title{
	font-size: rem(24);	
}

.section-subtitle{
	font-size: rem(18);
}

.section-note{
	margin: 0 0 $spacer 0;
	color: $light;
	font-style: italic;

	@include media-breakpoint-up(lg) {
		text-align: right;
	}
}
