.categories_container{
	display: flex;
	flex-wrap: wrap;
	margin-left: -$spacer / 2;
	width: calc(100% + #{$spacer});

	.category{
		text-align: center;
		min-height: 128px;
		border: 1px solid $gray-300;
		@include border-radius;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: $spacer;
		margin: 0 $spacer / 2 $spacer;
		position: relative;
		padding: $spacer / 2;
		cursor: pointer;

		.category-thumbnail{
			font-size: rem(36);
		}

		.category-text{
			font-weight: 700;
		}

		&.active{
			background-color: $white;
			border-color: $primary;

			&::after{
				font-family: 'jobaround';
				position: absolute;
				content: "\f00c";
				background-color: $primary;
				width: 20px;
				height: 20px;
				border-radius: 20px;
				right: -10px;
				color: $white;
				top: $spacer;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.category{
			flex: 0 0 calc(50% - #{$spacer});
		}
	}

	@include media-breakpoint-up(md) {
		.category{
			flex: 0 0 calc(50% - #{$spacer});
		}
	}

	@include media-breakpoint-up(lg) {
		.category{
			flex: 0 0 calc(33% - #{$spacer});
		}
	}

	@include media-breakpoint-up(xl) {
		.category{
			flex: 0 0 calc(25% - #{$spacer});
		}
	}
}