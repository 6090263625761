// UPLOAD BUTTON

.user-thumbnail-upload,
.photo-thumbnail-upload{
	position: absolute;
	width: 34px;
	height: 34px;
	z-index: 3;

	input.custom-file-input{
		display: none;
	}

	.upload-picture{
		border: 0;
		width: 34px;
		height: 34px;
		border-radius: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		cursor: pointer;
		transition: background-color .2s ease-out;
		font-size: rem(14);

		i{
			color: $white;
		}

		&:hover{
			background-color: darken($primary, 10%)
		}
	}
}

.user-thumbnail-upload{
	top: 10px;
	right: 10px;
}

.photo-thumbnail-upload{
	top: 10px;
	right: -17px;
}

.upload-file,
.upload-image{
	display: inline-block;
	height: 24px;
	font-size: rem(22);
	margin-right: 4px;
	cursor: pointer;

	label{
		margin-bottom: 0;
	}
	
	input{
		display: none;
	}
}

.user-details-modify{
	position: absolute;
	right: calc(-34px - 10px);
	top: 10px;
	width: 34px;
	height: 34px;
	border-radius: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color .2s ease-in-out;
	cursor: pointer;
}

// colors

.b2b{
	.user-details-modify{
		background-color: $salmon;

		&:hover{
			background-color: darken($salmon, 10%);
		}
	}

	.user-thumbnail-upload,
	.photo-thumbnail-upload{
		.upload-picture{
			background-color: $salmon;

			&:hover{
				background-color: darken($salmon, 10%);
			}
		}
	}
}

.b2c{
	.user-details-modify{
		background-color: $primary;

		&:hover{
			background-color: darken($primary, 10%);
		}
	}

	.user-thumbnail-upload,
	.photo-thumbnail-upload{
		.upload-picture{
			background-color: $primary;

			&:hover{
				background-color: darken($primary, 10%);
			}
		}
	}
}