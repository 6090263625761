// BADGES

.badge{
	width: 34px;
	height: 34px;
	border-radius: 34px;
	font-size: rem(16);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	@include hover-focus-active {background-color: darken($secondary, 10%);}

	&.badge-sm{
		width: 26px;
		height: 26px;
		border-radius: 26px;
		font-size: rem(14);
	}

	&.badge-red{
		background-color: $salmon;
		color: $white;
		@include hover-focus-active {background-color: darken($salmon, 10%);}
	}

	&.badge-yellow{
		background-color: $yellow;
		color: $dark;
		@include hover-focus-active {background-color: darken($yellow, 10%);}
	}

	&.badge-blue{
		background-color: $blue;
		color: $white;
		@include hover-focus-active {background-color: darken($blue, 10%);}
	}

	&.badge-kaki{
		background-color: $kaki;
		color: $white;
		@include hover-focus-active {background-color: darken($kaki, 10%);}
	}

	&.badge-gray,
	&.badge-light{
		background-color: $light;
		color: $white;
		@include hover-focus-active {background-color: darken($light, 10%);}
	}

	&.badge-lighter{
		background-color: $lighter;
		color: $secondary;
		@include hover-focus-active {background-color: darken($light, 10%);}
	}

	&.badge-cyan{
		background-color: $cyan;
		color: $white;
		@include hover-focus-active {background-color: darken($cyan, 10%);}
	}

	&.badge-primary{
		background-color: $primary;
		color: $dark;
		@include hover-focus-active {background-color: darken($primary, 10%);}
	}

	&.badge-secondary{
		background-color: $secondary;
		color: $white;
		@include hover-focus-active {background-color: darken($secondary, 10%);}
	}
}
