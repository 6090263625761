////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color:                   #0b0b0b !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px !default;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       8px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1090 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  true !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default;                       // Opacity of controls
$mfp-controls-color:                  #FFF !default;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$mfp-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px !default;                       // Iframe padding top
$mfp-iframe-background:               #000 !default;                       // Background color of iframes
$mfp-iframe-max-width:                900px !default;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default;                       // Image padding top
$mfp-image-padding-bottom:            40px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers


// MAGNIFIC POPUP CSS

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $mfp-z-index-base + 2;
	overflow: hidden;
	position: fixed;

	background: $mfp-overlay-color;
	opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
	top: 0 !important;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $mfp-z-index-base + 3;
	position: fixed;
	outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
	//text-align: center;
	position: absolute;
	width: 100%;
	//height: 100%;
	left: 0;
	top: 0;
	padding: $spacer;
	box-sizing: border-box;

	display: flex; //added
	align-items: center; //added
	justify-content: center; //added
	min-height: 100%; //added
	//height: auto; //added
	//overflow-x: scroll; //added
}

// Vertical centerer helper
//.mfp-container {
//	&:before {
//		content: '';
//		display: inline-block;
//		height: 100%;
//		vertical-align: middle;
//	}
//}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
	.mfp-container {
		&:before {
			display: none;
		}
	}
}

// Popup content holder
.mfp-content {
	position: relative;
	//display: inline-block;
	//vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: $mfp-z-index-base + 5;
}

.mfp-inline-holder,
.mfp-ajax-holder {
	.mfp-content {
		width: 100%;
		cursor: auto;
	}
}

// Cursors
.mfp-ajax-cur {
	cursor: progress;
}
.mfp-zoom-out-cur {
	&, .mfp-image-holder .mfp-close {
		cursor: -moz-zoom-out;
		cursor: -webkit-zoom-out;
		cursor: zoom-out;
	}
}
.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.mfp-auto-cursor {
	.mfp-content {
		cursor: auto;
	}
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select:none;
	-moz-user-select: none;
	user-select: none;
}

// Hide the image during the loading
.mfp-loading {
	&.mfp-figure {
		display: none;
	}
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
  	border: 0 !important;
  	clip: rect(0 0 0 0) !important;
  	height: 1px !important;
  	margin: -1px !important;
  	overflow: hidden !important;
  	padding: 0 !important;
  	position: absolute !important;
  	width: 1px !important;
  }
} @else {
	.mfp-hide {
		display: none !important;
	}
}


////////////////////////
// 2. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
	color: $mfp-controls-text-color;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: $mfp-z-index-base + 4;
	a {
		color: $mfp-controls-text-color;
		&:hover {
			color: $mfp-controls-text-color-hover;
		}
	}
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
	.mfp-preloader {
		display: none;
	}
}

// Hide content when it was not loaded
.mfp-s-error {
	.mfp-content {
		display: none;
	}
}

// CSS-reset for buttons
button {
	&.mfp-close,
	&.mfp-arrow {
		overflow: visible;
		cursor: pointer;
		background: transparent;
		border: 0;
		-webkit-appearance: none;
		display: block;
		outline: none;
		padding: 0;
		z-index: $mfp-z-index-base + 6;
		box-shadow: none;
		touch-action: manipulation;
	}
	&::-moz-focus-inner {
		padding: 0;
		border: 0
	}
}


// Close icon
.mfp-close {
	width: 34px;
	height: 34px;
	line-height: 34px;
	position: absolute;
	border-radius: 34px;
	transition: background-color .1s ease-in-out, transform .2s ease-in-out;
	cursor: pointer;

	&:hover{
		transform: scale(1.1);
	}
}

.mfp-close-btn-in {
	.mfp-close {
		color: $mfp-inner-close-icon-color;
	}
}

.mfp-image-holder,
.mfp-iframe-holder {
	.mfp-close {
		color: $mfp-controls-color;
		right: -6px;
		text-align: right;
		padding-right: 6px;
		width: 100%;
	}
}

// "1 of X" counter
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: $mfp-controls-text-color;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
	.mfp-arrow {
		position: absolute;
		opacity: $mfp-controls-opacity;
		margin: 0;
		top: 50%;
		margin-top: -55px;
		padding: 0;
		width: 90px;
		height: 110px;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		&:active {
			margin-top: -54px;
		}
		&:hover,
		&:focus {
			opacity: 1;
		}
		&:before,
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			left: 0;
			top: 0;
			margin-top: 35px;
			margin-left: 35px;
			border: medium inset transparent;
		}

		&:after {

			border-top-width: 13px;
			border-bottom-width: 13px;
			top:8px;
		}

		&:before {
			border-top-width: 21px;
			border-bottom-width: 21px;
			opacity: 0.7;
		}

	}

	.mfp-arrow-left {
		left: 0;
		&:after {
			border-right: 17px solid $mfp-controls-color;
			margin-left: 31px;
		}
		&:before {
			margin-left: 25px;
			border-right: 27px solid $mfp-controls-border-color;
		}
	}

	.mfp-arrow-right {
		right: 0;
		&:after {
			border-left: 17px solid $mfp-controls-color;
			margin-left: 39px
		}
		&:before {
			border-left: 27px solid $mfp-controls-border-color;
		}
	}
}



// Iframe content type
@if $mfp-include-iframe-type {
	.mfp-iframe-holder {
		padding-top: $mfp-iframe-padding-top;
		padding-bottom: $mfp-iframe-padding-top;
		.mfp-content {
			line-height: 0;
			width: 100%;
			max-width: $mfp-iframe-max-width;
		}
		.mfp-close {
			top: -40px;
		}
	}
	.mfp-iframe-scaler {
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-top: $mfp-iframe-ratio * 100%;
		iframe {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: $mfp-shadow;
			background: $mfp-iframe-background;
		}
	}
}



// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
  	&.mfp-img {
  		width: auto;
  		max-width: 100%;
  		height: auto;
  		display: block;
  		line-height: 0;
  		box-sizing: border-box;
  		padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
  		margin: 0 auto;
  	}
  }

  /* The shadow behind the image */
  .mfp-figure {
  	line-height: 0;
  	&:after {
  		content: '';
  		position: absolute;
  		left: 0;
  		top: $mfp-image-padding-top;
  		bottom: $mfp-image-padding-bottom;
  		display: block;
  		right: 0;
  		width: auto;
  		height: auto;
  		z-index: -1;
  		box-shadow: $mfp-shadow;
  		background: $mfp-image-background;
  	}
  	small {
  		color: $mfp-caption-subtitle-color;
  		display: block;
  		font-size: 12px;
  		line-height: 14px;
  	}
  	figure {
  		margin: 0;
  	}
  }
  .mfp-bottom-bar {
  	margin-top: -$mfp-image-padding-bottom + 4;
  	position: absolute;
  	top: 100%;
  	left: 0;
  	width: 100%;
  	cursor: auto;
  }
  .mfp-title {
  	text-align: left;
  	line-height: 18px;
  	color: $mfp-caption-title-color;
  	word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
}

.mfp-image-holder {
	.mfp-content {
		max-width: 100%;
	}
}

.mfp-gallery {
	.mfp-image-holder {
		.mfp-figure {
			cursor: pointer;
		}
	}
}


@if $mfp-include-mobile-layout-for-image {
	@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
      /**
       * Remove all paddings around the image on small screen
       */
       .mfp-img-mobile {
       	.mfp-image-holder {
       		padding-left: 0;
       		padding-right: 0;
       	}
       	img {
       		&.mfp-img {
       			padding: 0;
       		}
       	}
       	.mfp-figure {
          // The shadow behind the image
          &:after {
          	top: 0;
          	bottom: 0;
          }
          small {
          	display: inline;
          	margin-left: 5px;
          }
      }
      .mfp-bottom-bar {
      	background: rgba(0,0,0,0.6);
      	bottom: 0;
      	margin: 0;
      	top: auto;
      	padding: 3px 5px;
      	position: fixed;
      	box-sizing: border-box;
      	&:empty {
      		padding: 0;
      	}
      }
      .mfp-counter {
      	right: 5px;
      	top: 3px;
      }
      .mfp-close {
      	top: 0;
      	right: 0;
      	width: 35px;
      	height: 35px;
      	line-height: 35px;
      	background: rgba(0, 0, 0, 0.6);
      	position: fixed;
      	text-align: center;
      	padding: 0;
      }
  }
}
}
}



// Scale navigation arrows and reduce padding from sides
@include media-breakpoint-down(md) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
}

//@include media-breakpoint-only(xs) {
//	.mfp-container {
//		padding: $spacer / 2;
//	}
//}
//
//@include media-breakpoint-only(sm) {
//	.mfp-container {
//		padding: $spacer;
//	}
//}
//
//@include media-breakpoint-up(md) {
//	.mfp-container {
//		padding: $spacer * 2 $spacer $spacer;
//	}
//}



// JOBAROUND

// side popups

.side-popup{
	background-color: $white;
	min-height: 100vh;

	@include media-breakpoint-down(sm) {
		padding: $spacer * 2;
	}

	@include media-breakpoint-up(md) {
		padding: $spacer * 2 $spacer * 4;
	}

	.content{
		@include media-breakpoint-down(sm) {
			padding-top: 58px;
		}
	}

	header{
		h1{
			strong{font-weight: 700;}
		}

		&:not(.profile-header){
			@include media-breakpoint-down(sm) {
				padding: $spacer;
				box-shadow: 0 0 2px 2px rgba(66, 74, 95, .1);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				min-height: 58px;
				padding: $spacer 68px;

				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				text-align: center;

				h1{
					font-size: rem(18);
					margin: 0;
					vertical-align: middle;
					display: inline-block;
				}
			}
			
			@include media-breakpoint-up(md) {
				h1{
					font-weight: 300;
					font-size: rem(28);
					margin-bottom: $spacer * 3;
				}
			}
		}
		
	}

	&.side-popup-small{

	}

	&.side-popup-medium{

	}

	&.side-popup-big{
		aside.sidebar{
			margin-top: 0;
			//min-height: 100%;
		}

		&.layout1{
			padding: 0;
			position: relative;

			> .row > .col-lg-4{
				background-color: $lighter;
			}

			.back{
				@include media-breakpoint-up(lg) {color:$white;}
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				padding: $spacer;
			}

			.content-left{
				background-color: $lighter;
			}

			.content-right{}

			.sidebar{
				background-color: $lighter;
				margin-top: 0;
				padding-top: 0;

				.side-image img{
					width: 100%;
					height: auto;
				}
			}

			.side-colophon{
				@include media-breakpoint-down(lg) {padding: $spacer;}
				@include media-breakpoint-up(xl) {padding: $spacer * 2;}
				background-color: $lighter;

				.card{
					font-family: $font-family-alt;
					background-color: $lighter;
					font-size: rem(12);
					color: $secondary;

					strong{
						color: $dark;
						font-size: rem(14);
					}
				}
			}

			main.content{
				display: flex;
				flex-direction: column;
				min-height: 100%;

				@include media-breakpoint-only(xs) {
					padding: $spacer * 5 $spacer $spacer;
				}
				@include media-breakpoint-only(sm) {
					padding: $spacer * 5 $spacer * 2 $spacer * 2;
				}
				@include media-breakpoint-up(md) {
					padding: $spacer * 2;
				}

				footer{
					@include media-breakpoint-up(lg) {
						flex: 1 0 auto;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
					}
					
				}

				.btn{
					width: auto;
				}
			}
		}

		&.layout2{
			padding: 0;
		}

		&.layout3{
			padding: 0;
			position: relative;

			>.row{
				min-height: 100vh;
			}

			.back{
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				padding: $spacer;
			}

			main.content{
				@include media-breakpoint-down(md) {
					padding: $spacer;
				}
				@include media-breakpoint-up(lg) {
					padding: $spacer * 3 $spacer * 2 $spacer * 2;
				}
				
			}
		}

		&.layout4{
			padding: 0;
			position: relative;

			.back{
				@include media-breakpoint-up(md) {color:$white;}
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				padding: $spacer;
			}
		}
	}

	.back{
		font-family: $font-family-alt;
		color: $secondary;
		margin-bottom: $spacer;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.mfp-close{
		@include media-breakpoint-down(sm) {
			font-size: rem(20);
			border-radius: 0;
			border: 0;
			top: .9rem;
			left: 24px;
		}

		@include media-breakpoint-up(md) {
			background-color: $primary;
			top: $spacer * 2;
			left: -17px;

			&:hover{
				background-color: darken($primary, 10%);
			}
		}

		&::after{
			@include media-breakpoint-down(sm){
				content: "\e909";
				font-family: "jobaround";
				color: $secondary;
			}

			@include media-breakpoint-up(md){
				content: "\f00d";
				font-family: "jobaround";
				color: $white;
				font-size: rem(16);
				padding: 10px;
			}
		}
	}

}

// white popup

.white-popup{
	margin-left: auto;
	margin-right: auto;
	position: relative;

	@include media-breakpoint-up(md) {
		max-width: 500px;
	}

	.mfp-close{
		background-color: $yellow;
		
		@include media-breakpoint-down(sm) {
			top: 10px;
			right: 10px;
		}

		@include media-breakpoint-up(md) {
			top: -16px;
			right: -16px;
		}

		&::after{
			content: "\f00d";
			font-family: "jobaround";
			color: $white;
			font-size: rem(16);
			padding: 10px;
		}
	}
}

// animations

.mfp-fade{
	
	// background
	&.mfp-bg{
		opacity: 0;
		transition: all .3s ease-in-out;

		&.mfp-ready{
			opacity: .5;
		}

		&.mfp-removing{
			opacity: 0;
		}
	}

	// content

	&.mfp-wrap{
		.mfp-content{
			opacity: 0;
			transform: scale(.8);
			transition: all .2s ease-out;
		}

		&.mfp-ready .mfp-content{
			opacity: 1;
			transform: scale(1);
		}

		&.mfp-removing .mfp-content{
			opacity: 0;
			transform: scale(.8);
		} 
	}
}

.mfp-slide{

	// container & content

	.mfp-container{
		padding: 0;

		.mfp-content{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transition: margin-left .5s ease-in-out;
		}
	}

	// background

	&.mfp-bg{
		opacity: 0;
		transition: all .3s ease-in-out;

		&.mfp-ready{
			opacity: .5;
		}

		&.mfp-removing{
			opacity: 0;
		}
	}

	// side popup small

	&.side-popup-small{
		&.mfp-wrap{
			.mfp-content{
				
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 450px;
					margin-left: calc(100vw + 450px);
				}
			}

			&.mfp-ready .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 0;
				}

				@include media-breakpoint-up(md) {
					width: 450px;
					margin-left: calc(100vw - 450px);
				}
			}

			&.mfp-removing .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 450px;
					margin-left: calc(100vw);
				}
			} 
		}
	}

	// side popup medium

	&.side-popup-medium{
		&.mfp-wrap{
			.mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 700px;
					margin-left: 100vw;
				}
			}

			&.mfp-ready .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 0;
				}

				@include media-breakpoint-up(md) {
					width: 700px;
					margin-left: calc(100vw - 700px);
				}
			}

			&.mfp-removing .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 700px;
					margin-left: 100vw;
				}
			} 
		}
	}

	// side popup big

	&.side-popup-big{

		&.mfp-wrap{
			.mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 90vw;
					margin-left: 100vw;
				}
			}

			&.mfp-ready .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 0;
				}

				@include media-breakpoint-up(md) {
					width: 90vw;
					margin-left: calc(100vw - 90vw);
				}
			}

			&.mfp-removing .mfp-content{
				@include media-breakpoint-down(sm) {
					width: 100vw;
					margin-left: 100vw;
				}

				@include media-breakpoint-up(md) {
					width: 90vw;
					margin-left: 100vw;
				}
			} 
		}
	}
}
