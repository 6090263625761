// USER THUMBNAIL

$user-image-sm:				25px !default;
$user-image-md:				40px !default;
$user-image-lg:				160px !default;
$user-image-border-color: 	$white !default;

.user-thumbnail{
	position: relative;
	display: inline-block;
	font-size: 0;

	.user-thumbnail-image{
		display: inline-block;
		position: relative;
		border-radius: 160px;
		overflow: hidden;
		z-index: 2;
		max-width: 100%;
		max-height: 100%;

		img{
			max-width: 100%;
			max-height: 100%;
		}
	}

	.user-thumbnail-shadow{
		width: 70%;
		height: 6px;
		background-color: $light;
		border-radius: 100%;
		filter: blur(2px);
		opacity: .5;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35%;
	}

	&.user-thumbnail-sm{
		.user-thumbnail-image{
			width: $user-image-sm;
			height: $user-image-sm;
			border: 3px solid $user-image-border-color;
			border-radius: $user-image-sm;
		}

		.profile-thumbnail img{
			width: $user-image-sm;
			height: $user-image-sm;
			border-radius: $user-image-sm;
		}
	}

	&.user-thumbnail-md{

		.user-thumbnail-image{
			width: $user-image-md;
			height: $user-image-md;
			border: 3px solid $user-image-border-color;
			border-radius: $user-image-md;
		}

		.profile-thumbnail img{
			width: $user-image-md;
			height: $user-image-md;
			border-radius: $user-image-md;
		}
	}

	&.user-thumbnail-lg{

		.user-thumbnail-image{
			width: calc(#{$user-image-lg} + 12px);
			height: calc(#{$user-image-lg} + 12px);
			border-radius: $user-image-lg;
			border: 6px solid $user-image-border-color;

			@include media-breakpoint-down(sm) {
				border: 3px solid $user-image-border-color;
			}
		}

		.profile-thumbnail img{
			width: $user-image-lg;
			height: $user-image-lg;
			border-radius: $user-image-lg;
		}
	}

	&.user-thumbnail-xl{
		width: $user-image-lg;
		height: $user-image-lg;
	}
}