.checklist{
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;
	font-size: rem(13);

	.checklist-title{
		border-bottom: 1px solid $border-color;
		color: $light;
		padding: $spacer;
		font-family: $font-family-alt;
	}

	.checklist-items{
		padding: $spacer / 2 0;

		.checklist-item{
			padding: $spacer / 2 $spacer;
			font-weight: 700;
			position: relative;
			display: flex;
			align-items: center;

			@include hover-focus-active {
				background-color: $lighter;
				cursor: pointer;
			}

			label{
				display: block;
				margin-bottom: 0;
				width: 100%;
			}

			input{
				display: none;

				& + label::after{
					font-family: 'jobaround';
					color: $secondary;
					content: "\e916";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: $spacer / 2 $spacer;
				}

				&:checked + label::after{
					content: "\e932";
					color: $primary;
				}
			}
		}
	}

	
}