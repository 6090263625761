aside.recipients{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: $white;
	z-index: $zindex-recipients;
	box-shadow: 0 0 20px rgba(0,0,0,.2);
	display: flex;
	padding: 0 $spacer;

	.recipients-container{
		position: relative;
		top: -20px;
		flex: 1 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		max-width: calc(100% - 15px - 120px);
	}

	.action-container{

	}

	.btn-link{

		color: $salmon;
		text-decoration: none;

		@include hover-focus-active {
			color: darken($salmon, 20%);
		}

	}

	@include media-breakpoint-down(xs) {
		flex-wrap: wrap;
		height: 55px;

		.recipients-container{
			flex: 1 0 100%;
			text-align: left;
			height: 36px;
			margin-left: -$spacer;
			margin-right: -$spacer;
			padding: 0 $spacer;
		}

		.action-container{
			text-align: right;
			flex: 1 0 100%;
			position: relative;
			top: -20px;

			.btn{
				padding: $spacer / 2;
			}
		}
	}

	@include media-breakpoint-only(sm) {

		.recipients-container{
			text-align: right;
		}

		.action-container{
		}
	}

	@include media-breakpoint-up(md) {

		.recipients-container{
			text-align: right;
		}

		.action-container{
		}
	}
}

.recipient{
	display: inline-block;
	vertical-align: middle;
	position: relative;

	@include media-breakpoint-down(sm) {
		margin-left: $spacer / 4;
	}

	@include media-breakpoint-up(md) {
		margin-left: $spacer / 2;
	}

	.user-thumbnail{
		border-radius: 56px;
		overflow: hidden;
		border: 3px solid $white;
		background-color: $light;

		@include media-breakpoint-down(sm) {
			width: 36px;
			height: 36px;
			img{
				width: 30px;
				height: 30px;
			}
		}

		@include media-breakpoint-up(md) {
			width: 56px;
			height: 56px;
			img{
				width: 50px;
				height: 50px;
			}
		}
	}

	.badge{
		position: absolute;
		top: 0;
		left: 0;
	}

	.badge-red{
		background-color: $light;
		border: 1px solid $white;
	}

	&.tmp_candidate{
		.badge-red{
			background-color: $salmon;
			border-color: $salmon;
			color: $white;
		}

		.user-thumbnail{
			border-color: $salmon;
		}
	}

	&.empty_candidate{
	}
}