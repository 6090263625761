.pellet{
	background-color: $secondary;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	font-size: rem(12);
	font-weight: 700;

	&.pellet-primary{
		background-color: $primary;
		color: $white;
	}

	&.pellet-secondary{
		background-color: $secondary;
		color: $white;
	}

	&.pellet-yellow{
		background-color: $yellow;
		color: $white;
	}

	&.pellet-blue{
		background-color: $blue;
		color: $white;
	}
	
	&.pellet-salmon{
		background-color: $salmon;
		color: $white;
	}

	&.pellet-red{
		background-color: $red;
		color: $white;
	}

	&.pellet-kaki{
		background-color: $kaki;
		color: $white;
	}

	&.pellet-light{
		background-color: $light;
		color: $white;
	}

	&.pellet-lighter{
		background-color: $lighter;
		color: $white;
	}

	&.pellet-dark{
		background-color: $dark;
		color: $white;
	}

	&.pellet-cyan{
		background-color: $cyan;
		color: $white;
	}
}
