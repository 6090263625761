.erreur-404{
	text-align: center;
	padding: $spacer * 3 0 $spacer * 5;

	h1{
		font-weight: 300;
		margin-bottom: $spacer * 3;

		strong{
			font-weight: 700;
			color: $salmon;
			display: block;
		}
	}

	p{
		color: $secondary;

		a{
			color: $salmon;

			@include hover-focus-active {
				color: darken($salmon, 10%);
			}
		}
	}
}