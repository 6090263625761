.story_pop{
	position: absolute;
	z-index: $zindex-hero-home-story_pop;

	a{
		color: $white;
		text-decoration: underline;
		@include hover-focus-active {
			color: $white;
		}
	}

	//&:before{
	//	content:'';
	//	background-image: url('../img/graphics/home/story_pop.png');
	//	width: 86px;
	//	height: 86px;
	//	display: block;
	//	position: absolute;
	//	left: -43px;
	//	top: -90px;
	//}

	.story_pop-name{
		font-size: rem(20);
		font-weight: 700;
	}

	.story_pop-job{
		font-size: rem(13);
		font-weight: 400;
	}

	.story_pop-link{
		font-size: rem(13);
		font-weight: 700;
		white-space: nowrap;

		& > * {display: inline;}
	}

	@include media-breakpoint-down(md) {display: none;}
}
