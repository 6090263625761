// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}


// Custom 5ths grid size

.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths{
  position: relative;
  min-height: 1px;
  padding-right: $grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;
}

.col-5ths{
  flex: 0 0 20%;
  max-width: 20%;
}

.offset-5ths{
  margin-left: 20%;
}

@include media-breakpoint-up(sm) {
  .col-sm-5ths{
    flex: 0 0 20%;
    max-width: 20%;
  }

  .offset-sm-5ths{
    margin-left: 20%;
  }
}

@include media-breakpoint-up(md) {
  .col-md-5ths{
    flex: 0 0 20%;
    max-width: 20%;
  }

  .offset-md-5ths{
    margin-left: 20%;
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-5ths{
    flex: 0 0 20%;
    max-width: 20%;
  }

  .offset-lg-5ths{
    margin-left: 20%;
  }
}

@include media-breakpoint-up(xl) {
  .col-xl-5ths{
    flex: 0 0 20%;
    max-width: 20%;
  }

  .offset-xl-5ths{
    margin-left: 20%;
  }
}
